import React from 'react';

const FaqEmptyScreen = () => {
  return (
    <div className="mt-60">
      <div className="text-center">
        <label className="md:font-normal italic md:text-[24px] md:leading-[27.36px] text-[#5F5F5F]">
          Choose to upload the File or Link from the Button
        </label>
      </div>
    </div>
  );
};
export default FaqEmptyScreen;
