import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SettingsSidebar from '../components/settings/SettingsSidebar';
import Widgets from '../components/client-setup/pages/widgets/Widgets';
import Faq from '../components/client-setup/pages/faq/Faq';
import { Integration } from '../components/client-setup/pages/account-integration/Integration';
import SettingUsers from '../components/setting-users/SettingUsers';
import CreateUserSetting from '../components/setting-users/CreateUserSetting';

const SettingsNavigation = () => {
  const settingsNavigation = [
    {
      path: 'widgets',
      element: (
        <SettingsSidebar>
          {' '}
          <Widgets isSettings={true} />
        </SettingsSidebar>
      )
    },
    {
      path: 'faq',
      element: <SettingsSidebar> </SettingsSidebar>
    },
    {
      path: 'integration',
      element: (
        <SettingsSidebar>
          {' '}
          <Integration isSettings={true} />
        </SettingsSidebar>
      )
    },
    {
      path: 'users',
      element: (
        <SettingsSidebar>
          <SettingUsers />{' '}
        </SettingsSidebar>
      )
    },
    {
      path: 'users/create-user',
      element: (
        <SettingsSidebar>
          <CreateUserSetting />{' '}
        </SettingsSidebar>
      )
    },
    {
      path: 'users/update-user/:id',
      element: (
        <SettingsSidebar>
          <CreateUserSetting />{' '}
        </SettingsSidebar>
      )
    }
  ];

  return (
    <Routes>
      {settingsNavigation.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default SettingsNavigation;
