import './App.css';
import React from 'react';
// import AccountSetupNavigation from './navigation/AccountSetupNavigation';
import Navigation from './navigation/Navigation';
import { SocketProvider } from './Contexts/SocketContext';

const App = () => {

  return  <SocketProvider>
  <Navigation/>
  </SocketProvider>
};

export default App;
