import NotificationCard from './NotificationCard';
import { RxCross1 } from 'react-icons/rx';
interface IProps {
  tickets: any;
  selectedTicketIdx: any;
  setSelectedTicketIdx: any;
  markAsRead: any;
}

const NotificationList = (props: IProps) => {
  const { tickets, selectedTicketIdx, setSelectedTicketIdx, markAsRead } = props;

  return (
    <>
      <div className="flex flex-col gap-2.5 xl:h-[calc(100vh-224px)] xl:overflow-y-auto p-2">
        <div className="flex justify-between items-center">
          <div className="flex gap-5">
            <label className="text-[22px] text-white pl-[10px] font-bold">Notifications</label>
          </div>
          <RxCross1 color="#fff" className="w-[26px] h-[26px]" />
        </div>
        <hr className="border-[#696969] border-1" />
        {tickets.map((info: any, index: number) => (
          <NotificationCard
            info={info}
            index={index}
            selectedTicketIdx={selectedTicketIdx}
            setSelectedTicketIdx={setSelectedTicketIdx}
            markAsRead={markAsRead}
          />
        ))}
      </div>
    </>
  );
};
export default NotificationList;
