import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icon from '../../common/Icon';
import Links from '../../common/Links';
import Button from '../../common/Button';
import InputBox from '../../common/InputBox';
import MatrixAiLogo from '../../common/MatrixAiLogo';
import { useNavigate } from 'react-router-dom';
import { loginUser, verifyUser } from '../../api/Auth';
import ToastNotification from '../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import { validateForm } from '../../function/FormValidation';
import { useSocket } from '../../Contexts/SocketContext';
import EyeButton from '../../common/EyeButton';

const Login = () => {
  const navigate = useNavigate();
  const { connectSocket } = useSocket();
  const { uuid } = useParams<{ uuid: string }>();

  const [disable, setDisable] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const verifyEmail = async (userUuid: string) => {
    try {
      const data = await verifyUser(userUuid);
      if (data && data.message) {
        ToastNotification({ message: 'user verified Successfully', type: 'success' });
      }
    } catch (error: any) {
      ToastNotification({ message: 'Error during verification', type: 'error' });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (uuid) {
      verifyEmail(uuid);
    }
  }, [uuid]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData, ['email', 'password']);
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      try {
        // Call the login API
        const response = await loginUser({
          email: formData.email || '',
          password: formData.password || ''
        });

        //Check if the API response is successful
        if (response.ok) {
          const data = await response.json();
          const { token, userUUID, companyUuid, companyName, setting } = data.payload;
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('userUUID', userUUID);
          sessionStorage.setItem('companyUuid', companyUuid);
          sessionStorage.setItem('companyName', companyName);
          connectSocket(userUUID);

          ToastNotification({
            message: data.message
          });
          setTimeout(() => {
            if (setting === 'finished') {
              navigate('/dashboard');
              setDisable(false);
            } else {
              navigate('/account-setup/create-company-profile');
              setDisable(false);
            }
            setFormData({
              email: '',
              password: ''
            });
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'error'
          });
          setDisable(false);
        }
      } catch (error: any) {
        setDisable(false);
      }
    }
  };

  return (
    <div className="flex h-full flex-col justify-center  px-2 py-3 lg:px-8">
      <MatrixAiLogo />
      <div className="sm:mx-auto sm:w-full sm:max-w-lg rounded-lg p-2">
        <h1 className="text-start text-[25px]   md:text-[46px] md:leading-[50.25px] font-bold tracking-tight">
          Hello &#58;&#41;
        </h1>
        <h1 className="font-normal sm:text-[20px] md:text-[28px] sm:leading-[31.28px] md:leading-[39.28px]">
          Login to your account
        </h1>
        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-lg">
          <form className="space-y-3" onSubmit={handleSubmit} method="Post">
            <div>
              <div>
                <InputBox
                  name="email"
                  type="text"
                  value={formData.email}
                  handleChange={handleChange}
                  placeholder="Email"
                  error={errors.email}
                />
              </div>
            </div>
            <div>
              <div className="relative">
                <InputBox
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  handleChange={handleChange}
                  placeholder="Password"
                  error={errors.password}
                />
                <EyeButton
                  showPassword={showPassword}
                  togglePasswordVisibility={togglePasswordVisibility}
                />
              </div>
              <div className="grid justify-items-end">
                <div>
                  <Link
                    to="/forgot-password"
                    className=" font-normal underline  md:text-[20px] md:leading-[23.52px] sm:text-[18px] underline-offset-4 md:underline-offset-[6px] "
                  >
                    Forgot password
                  </Link>
                </div>
              </div>
            </div>
            <Button
              isFormValid={true}
              disable={disable}
              btnText="Login"
              textColor="#FFFFFF"
              backGroundColor="#006BEA"
              borderColor=""
            />
          </form>
          <div className="mt-[60px]">
            <Icon />
          </div>
          <div className="mt-[70px]">
            <Links linkText="Sign Up" link="/signup" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
