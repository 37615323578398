import { IoMdAdd, IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { HiMinusSm } from 'react-icons/hi';

interface IProps {
  index: number;
  info: any;
}

const StatusCard = (props: IProps) => {
  const { index, info } = props;

  return (
    <div className="bg-[#343434] rounded-lg p-2.5 pb-5" key={index}>
      <label className="text-white font-normal text-lg">{info.title}</label>
      <div className="flex flex-row justify-between mt-5">
        <label className="text-white font-bold text-3xl">{info.count}</label>
        {info.percent !== '' && (
          <div
            className={`flex flex-row ${info.status === 'positive' ? 'text-[#20B363]' : 'text-[#FA0000]'} font-semibold justify-center`}
          >
            {info.status === 'positive' ? <IoMdAdd /> : <HiMinusSm />}
            <label className="text-xs">{info.percent}</label>
            {info.status === 'positive' ? (
              <IoMdArrowRoundUp size={14} className="ml-1" />
            ) : (
              <IoMdArrowRoundDown size={14} className="ml-1" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusCard;
