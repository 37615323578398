import { useEffect, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { LuClock } from 'react-icons/lu';
import { TfiAngleDown } from 'react-icons/tfi';
import ToastNotification from '../../common/ToastNotification';
import { updateComplaintStatus } from '../../api/Complaints';
import React from 'react';
import { ToastContainer } from 'react-toastify';

interface IProps {
  tickets: any;
  setShowAssignPopup: (value: boolean) => void;
  setShowVendorAssignPopup: any;
  selectedComplaintsInfo: any;
  handleFetchTickets: any;
  currentPage: any;
}

const SpecificTicketDetails = (props: IProps) => {
  const companyUuid = sessionStorage.getItem('companyUuid') || '';

  const {
    tickets,
    setShowAssignPopup,
    setShowVendorAssignPopup,
    selectedComplaintsInfo,
    handleFetchTickets,
    currentPage
  } = props;

  const [selectedStatus, setSelectedStatus] = useState('');

  const handleChange = async (event: any, uuid: string) => {
    const UpdatedStatus = event.target.value;
    const getSelectedIndex = tickets.findIndex((info: any) => info.uuid === uuid);
    setSelectedStatus(UpdatedStatus);

    if (UpdatedStatus) {
      try {
        const response = await updateComplaintStatus(
          {
            complaintUuid: selectedComplaintsInfo.uuid,
            status: UpdatedStatus
          },
          companyUuid
        );
        if (response.ok) {
          const data = await response.json();
          handleFetchTickets('All', currentPage, '', '', getSelectedIndex);
          ToastNotification({
            message: data.message
          });
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    setSelectedStatus(selectedComplaintsInfo.status);
  }, [selectedComplaintsInfo.status]);

  return (
    <div className="xl:h-[calc(100vh-264px)] flex flex-col gap-3 xl:overflow-y-auto pr-2">
      {/* Ticket ID */}
      <div className="flex justify-between items-center">
        <div className="flex gap-8">
          <label className="text-xl text-white font-bold">Ticket ID</label>
          <label className="text-lg text-white font-normal">
            {selectedComplaintsInfo.ticketId || '-'}
          </label>
        </div>
        {selectedComplaintsInfo?.status === 'Assigned' ? (
          <LuClock color={'#006BEA'} size={24} />
        ) : (
          <label
            className={`text-lg font-bold cursor-pointer 
      ${
        selectedComplaintsInfo?.status === 'Resolved'
          ? 'text-[#28A745]'
          : selectedComplaintsInfo?.status === 'Reopened'
            ? 'text-[#FA812F]'
            : selectedComplaintsInfo?.status === 'Pending'
              ? 'text-[#FF4C4C]'
              : ''
      }`}
          >
            {selectedComplaintsInfo?.status || '-'}
          </label>
        )}
      </div>

      {/* Raised On */}
      <div className="flex gap-6">
        <label className="text-xl text-white font-bold">Raised on</label>
        <label className="text-lg text-white font-normal">
          {selectedComplaintsInfo.raisedOn
            ? new Date(selectedComplaintsInfo.raisedOn).toLocaleDateString()
            : '-'}
        </label>
      </div>

      {/* Description */}
      <div className="flex gap-4">
        <label className="text-xl text-white font-bold">Descrption</label>
        <label className="text-lg text-white font-normal">
          {selectedComplaintsInfo.description || '-'}
        </label>
      </div>

      {/* Product */}
      <div className="flex gap-11">
        <label className="text-xl text-white font-bold">Product</label>
        <label className="text-lg text-white font-normal">
          {selectedComplaintsInfo.product || '-'}
        </label>
      </div>

      {/* Assigned */}
      <div className="flex gap-8 items-center">
        <label className="text-xl text-white font-bold">Assigned</label>
        <div
          className="w-10 h-10 bg-[#D9D9D9] text-black rounded-full cursor-pointer flex items-center justify-center text-sm font-bold"
          onClick={() => setShowAssignPopup(true)}
        >
          {selectedComplaintsInfo.userAssigned ? (
            selectedComplaintsInfo.userAssigned.charAt(0).toUpperCase()
          ) : (
            <IoMdAdd color={'#343434'} size={22} />
          )}
        </div>
      </div>

      {/* Vendor */}
      <div className="flex gap-8 items-center">
        <label className="text-xl text-white font-bold">
          Vendor
          <br />
          Assigned
        </label>
        <div
          className="w-10 h-10 bg-[#D9D9D9] text-black rounded-full cursor-pointer flex items-center justify-center text-sm font-bold"
          onClick={() => setShowVendorAssignPopup(true)}
        >
          {selectedComplaintsInfo.vendor ? (
            selectedComplaintsInfo.vendor.charAt(0).toUpperCase()
          ) : (
            <IoMdAdd color={'#343434'} size={22} />
          )}
        </div>
      </div>

      {/* Status */}
      <div className="flex gap-16 items-center">
        <label className="text-xl text-white font-bold">Status</label>
        <div className="relative">
          <select
            name="type"
            className="bg-[#939393] outline-none text-white focus:bg-[#939393] active:bg-[#939393] text-lg font-normal rounded-lg px-5 py-1 pr-10 appearance-none cursor-pointer"
            // value={selectedComplaintsInfo.status || ''}
            value={selectedStatus}
            onChange={(e: any) => handleChange(e, selectedComplaintsInfo.uuid)}
          >
            <option value="Assigned">Assigned</option>
            <option value="Pending">Pending</option>
            <option value="Reopened">Reopened</option>
            <option value="Resolved">Resolved</option>
          </select>
          <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
            <TfiAngleDown />
          </span>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SpecificTicketDetails;
