import React, { useState, useRef, useEffect } from 'react';
import { ChromePicker, ColorResult } from 'react-color';

interface colorPickerProps {
  handleState: (field: string, value: string) => void;
  selectedTheme: any;
  listOfColor: string[];
}

const CustomColorPicker = (props: colorPickerProps) => {
  const { handleState, selectedTheme, listOfColor } = props;
// useEffect(()=>{
//   setColor(selectedTheme);
//   setInputColor(selectedTheme);
// },selectedTheme)
  const [color, setColor] = useState<string>(!listOfColor.includes(selectedTheme)?selectedTheme:'#FF4C4C');
  const [inputColor, setInputColor] = useState<string>(!listOfColor.includes(selectedTheme)?selectedTheme:'#FF4C4C');
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement | null>(null); 
  const pickerRef = useRef<HTMLDivElement | null>(null); 

  const handleChangeComplete = (color: ColorResult) => {
    setColor(color.hex.toUpperCase());
    setInputColor(color.hex.toUpperCase());
    handleState('selectedTheme', color.toString().toUpperCase());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^#[0-9A-Fa-f]{0,6}$/.test(value)) {
      setInputColor(value.toUpperCase());
      setColor(value.toUpperCase());
      handleState('selectedTheme', value.toUpperCase());
    }
  };
  const handleColorOnClick = () => {
    handleState('selectedTheme', color.toString().toUpperCase());
  };
  const togglePicker = () => setIsPickerOpen((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setIsPickerOpen(false);
      }
    };

    if (isPickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPickerOpen]);

  return (
    <div className="relative flex flex-col items-center">
      <div
        ref={buttonRef}
        onClick={togglePicker}
        className={`flex items-center bg-[#343434] text-white rounded-md shadow-md ${!listOfColor.includes(selectedTheme) ? 'border-2 border-white' : ''}`}
      >
        <span className="w-7 h-7 rounded-l-md mr-2" style={{ backgroundColor: color }}></span>
        <input
          type="text"
          value={inputColor}
          onChange={handleInputChange}
          className="bg-transparent text-white border-none focus:outline-none w-24 px-2"
          maxLength={7}
          onClick={handleColorOnClick}
        />
      </div>

      {isPickerOpen && (
        <div
          ref={pickerRef}
          className="absolute mt-2 z-50"
          style={{
            top: '100%',
            left: '75%',
            transform: 'translateX(-50%)'
          }}
        >
          <div
            className="bg-[#343434] rounded-lg shadow-lg p-4"
            style={{
              minWidth: '250px',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.25)'
            }}
          >
            <ChromePicker
              color={color}
              onChangeComplete={handleChangeComplete}
              styles={{
                default: {
                  picker: {
                    boxShadow: 'none',
                    background: '#343434',
                    borderRadius: '8px'
                  },
                  saturation: {
                    borderRadius: '4px'
                  },
                  swatch: {
                    boxShadow: 'none'
                  }
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomColorPicker;
