import React from 'react';
import SettingList from './SettingList';
import { RiAddLargeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

const SettingUsers = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" w-full">
        <div className="flex justify-between items-center mb-1 ps-4 pe-4">
          <h1 className="text-3xl font-bold text-white">Users</h1>
          <div
            className="p-2 bg-[#006BEA] rounded font-bold cursor-pointer me-2"
            onClick={() => navigate('create-user')}
          >
            <RiAddLargeFill size={18} />
          </div>
        </div>

        <SettingList />
      </div>
    </>
  );
};

export default SettingUsers;
