import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer, Label } from 'recharts';

const AnalysisChart = ({ engagement, allUser }: any) => {
  const loginUser = engagement?.loggedInUsers;
  const logOutUser =
    allUser?.length === engagement?.totalUsers
      ? engagement?.totalUsers - engagement?.loggedInUsers
      : '';
  const chartData = [
    { name: 'Login', value: loginUser, color: '#AFAFAF' },
    { name: 'Log out', value: logOutUser, color: '#FFFFFF' },
    { name: 'FAQ', value: 18, color: '#006BEA' }
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={300} height={300}>
        <Pie
          dataKey="value"
          data={chartData}
          cx={'60%'}
          cy={'50%'}
          innerRadius={70}
          outerRadius={110}
          cornerRadius={8}
          stroke="none"
          paddingAngle={3}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          <Label value="100%" position="center" fontSize={16} fontWeight="bold" fill="#ffffff" />
        </Pie>
        <Tooltip />
        <Legend
          align="right"
          verticalAlign="top"
          iconType="circle"
          iconSize={14}
          wrapperStyle={{ color: '#ffffff', borderRadius: 10 }}
          layout="vertical"
          formatter={(value) => <span className="text-sm font-bold text-white ml-2">{value}</span>}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default AnalysisChart;
