import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

interface SocketContextType {
  socketInstance: Socket | null;
  connectSocket: (userUUID: string) => void;
  disconnectSocket: () => void;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socketInstance, setSocketInstance] = useState<Socket | null>(null);

  const connectSocket = (userUUID: string) => {
    if (socketInstance) {
      return;
    }

    const validUserUUID = userUUID || sessionStorage.getItem('userUUID');

    if (!validUserUUID) {
      return;
    }

    const socket = io('https://api-support-system.sulopa.com', {
      auth: { userUuid: validUserUUID },
      transports: ['websocket']
    });

    setSocketInstance(socket);
  };
  const disconnectSocket = () => {
    if (socketInstance) {
      socketInstance.disconnect();
      setSocketInstance(null);
    }
  };

  useEffect(() => {
    const userUUID = sessionStorage.getItem('userUUID');
    if (userUUID) {
      connectSocket(userUUID);
    }
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);
  return (
    <SocketContext.Provider value={{ socketInstance, connectSocket, disconnectSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};
