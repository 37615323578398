import React, { useState, useEffect, Fragment } from 'react';
import { FaChevronLeft, FaChevronRight, FaPhoneAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { IoMdMail } from 'react-icons/io';
import { LuTrash } from 'react-icons/lu';
import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUser, deleteUser } from '../../api/SettingUser';
import ToastNotification from '../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import Loader from '../../common/Loader';

const SettingList = () => {
  const itemsPerPage = 4;
  const companyUuid = sessionStorage.getItem('companyUuid');

  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  // Fetch users on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowLoading(true);
        const result = await getUser(companyUuid || '');

        if (result?.payload?.result) {
          setData(result.payload.result);
          console.log('first', result.payload.result);
        } else {
          setData([]);
        }
        setShowLoading(false);
      } catch (error) {
        setShowLoading(false);
        console.error('Failed to fetch users:', error);
      }
    };

    fetchData();
  }, [companyUuid]);

  // Handle user deletion
  const deleteUserHandler = async (uuid: string) => {
    try {
      const response = await deleteUser(companyUuid || '', uuid);

      if (response.ok) {
        const responseData = await response.json();
        console.log('User deleted successfully');
        ToastNotification({
          message: responseData.message
        });

        // Optimistically update the state
        setData((prevData) => {
          const updatedData = prevData.filter((item: any) => item.uuid !== uuid);
          // If the current page becomes invalid, navigate to the previous page
          const newTotalPages = Math.ceil(updatedData.length / itemsPerPage);
          if (currentPage >= newTotalPages && newTotalPages > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
          }
          return updatedData;
        });
      } else {
        const errorData = await response.json();
        ToastNotification({
          message: errorData.message,
          type: 'warning'
        });
      }
    } catch (error: any) {
      console.error('Error deleting users:', error);
      ToastNotification({
        message: error.response?.data?.message || error.message || 'An unexpected error occurred.',
        type: 'error'
      });
    }
  };

  // Pagination logic
  const offset = currentPage * itemsPerPage;
  const currentData = data.slice(offset, offset + itemsPerPage);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (selectedItem: any) => {
    setCurrentPage(selectedItem.selected);
  };

  const navigate = useNavigate();

  // Handle edit navigation
  const handleEdit = (uuid: string, userData?: any) => {
    console.log('Navigating to update-user with data:', userData);
    navigate(`update-user/${uuid}`, { state: { userData } });
  };

  return (
    <>
      <div className="p-4">
        {showLoading ? (
          <div className="flex items-center justify-center h-[50vh] text-white text-lg font-bold">
            <Loader />
          </div>
        ) : currentData.length > 0 ? (
          <Fragment>
            <div className="overflow-y-auto pr-2">
              <table className="w-full text-white border-separate border-spacing-y-3">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left text-[16px]">ID</th>
                    <th className="py-2 text-left text-[16px]">Name</th>
                    <th className="py-2 text-left text-[16px]">Info</th>
                    <th className="py-2 px-2 text-left text-[16px]">Status</th>
                    <th className="py-2px-2 text-left text-[16px]">Complaints</th>
                    <th className="py-2 px-2 text-left text-[16px]">Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item: any, index: number) => (
                    <tr key={index} className="bg-[#343434] text-[16px]">
                      <td className="px-4 py-2 text-[16px] rounded-tl-md rounded-bl-md">
                        {item.uuid.slice(-12)}
                      </td>
                      <td className="py-2 text-[16px] px-2">{item.fullName}</td>
                      <td className="py-2 text-[16px] px-2">
                        <div>
                          <div className="flex mb-2">
                            <FaPhoneAlt className="w-5 h-5 mt-2 mr-2" />
                            <h6 className="text-[16px]">{item.phone}</h6>
                          </div>
                          <div className="flex">
                            <IoMdMail className="w-5 h-5 mt-1 mr-2 justify-center text-center" />
                            <h6 className="text-[16px] w-[140px] break-words leading-5">
                              {item.email}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td
                        className={`py-2 text-[16px] px-2 ${item.accesslog?.length === 0 ? 'text-red-500' : 'text-green-500'}`}
                      >
                        {item.accesslog?.length === 0 ? 'In Active' : 'Active'}
                      </td>
                      <td className="py-2 text-[16px] pl-10">{item.complaint.length || 0}</td>
                      <td className="px-4 py-2 text-[16px] rounded-tr-md rounded-br-md">
                        {' '}
                        <div className="flex">
                          <FiEdit
                            className="text-[#006BEA] w-7 h-7 cursor-pointer"
                            onClick={() => handleEdit(item.uuid, item)}
                          />
                          <LuTrash
                            className="text-[#FF4C4C] w-7 h-7 ml-3 cursor-pointer border-[#FFFFFF]"
                            onClick={() => deleteUserHandler(item.uuid)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="w-full flex justify-center items-center mb-8 mt-8">
                  <ReactPaginate
                    previousLabel={
                      <FaChevronLeft className="flex items-center justify-center w-6 h-6 p-1 border rounded border-white text-white" />
                    }
                    nextLabel={
                      <FaChevronRight className="flex items-center justify-center w-6 h-6 p-1 border rounded border-white text-white" />
                    }
                    pageCount={totalPages}
                    onPageChange={handlePageClick}
                    containerClassName="flex items-center justify-center gap-3"
                    activeClassName="text-[#006BEA] font-bold"
                  />
                </div>
              )}
            </div>
          </Fragment>
        ) : (
          <div className="flex items-center justify-center h-[50vh] text-white text-lg font-bold">
            No users found
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default SettingList;
