import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Faq from '../components/client-setup/pages/faq/Faq';
import Widgets from '../components/client-setup/pages/widgets/Widgets';
import CreateCompanyProfile from '../components/client-setup/pages/create-company-profile/CreateCompanyProfile';
import { Integration } from '../components/client-setup/pages/account-integration/Integration';

const AccountSetupNavigation = () => {
  const accountSetupNavigations = [
    {
      path: 'create-company-profile',
      element: <CreateCompanyProfile />
    },
    {
      path: 'faq',
      element: <Faq />
    },
    {
      path: 'widgets',
      element: <Widgets />
    },
    {
      path: 'integration',
      element: <Integration />
    }
  ];

  return (
    <Routes>
      {accountSetupNavigations.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default AccountSetupNavigation;
