import { RiAddLargeFill } from 'react-icons/ri';
import ListOfVendor from './vendor-list/ListOfVendor';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Vendors = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#1E1E1E] h-screen py-5 w-full overflow-y-auto px-2 flex flex-col gap-5 overflow-hidden">
      <div className={`flex items-center justify-between lg:px-6`}>
        <label className={`text-xl text-white font-bold `}>Vendors</label>
        <div
          className="p-2 bg-[#006BEA] rounded font-bold cursor-pointer me-2"
          onClick={() => navigate('add-vendor')}
        >
          <RiAddLargeFill size={18} />
        </div>
      </div>

      <ListOfVendor />
    </div>
  );
};

export default Vendors;
