import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../../common/Button';
import PopupFile from './PopupFile';
import PopupUrl from './AddUrlPopup';
import FaqEmptyScreen from './FaqEmptyScreen';
import FaqFillScreen from './FaqFillScreen';
import { BackButton } from '../../../../../common/BackButton';
import { FaRegFileAlt } from 'react-icons/fa';
import { RiLink } from 'react-icons/ri';
import { ToastContainer } from 'react-toastify';
import { deleteFaqDocument } from '../../../../../api/AccountSetup';
import ToastNotification from '../../../../../common/ToastNotification';
import { ImSpinner9 } from 'react-icons/im';

interface faqAutoProps {
  documentList: {
    uuid: string;
    type: string;
    name: string;
  }[];
  fetchAllDocuments: any;
  fetchGeneratedFAQ: any;
  setShowFaqAuto: any;
  disable: any;
  setFaqList: any;
  isLoading:boolean;
}

const FaqAuto = (props: faqAutoProps) => {
  const {
    documentList,
    fetchAllDocuments,
    fetchGeneratedFAQ,
    setShowFaqAuto,
    disable,
    setFaqList,
    isLoading
  } = props;

  const navigate = useNavigate();
  const companyUuid = sessionStorage.getItem('companyUuid');

  const [isPopupOpenFile, setIsPopupOpenFile] = useState(false);
  const [isPopupOpenUrl, setIsPopupOpenUrl] = useState(false);
  const [editUrlPopupData, setEditUrlPopupData] = useState();

  const openPopup1 = () => {
    setIsPopupOpenFile(true);
  };

  const openPopup2 = () => {
    setIsPopupOpenUrl(true);
  };

  const closePopup1 = () => {
    setIsPopupOpenFile(false);
  };

  const closePopup2 = () => {
    setEditUrlPopupData(undefined);
    setIsPopupOpenUrl(false);
  };

  const deleteDocuments = async (documentId: string) => {
    const res = await deleteFaqDocument(documentId, companyUuid ? companyUuid : '');
    if (res.ok) {
      ToastNotification({
        message: 'Deleted Successfully',
        type: 'success'
      });
      setTimeout(() => {
        fetchAllDocuments();
      }, 1500);
    }
  };
  const handleEditUrl = (info: any) => {
    setEditUrlPopupData(info);
    setIsPopupOpenUrl(true);
  };
  useEffect(() => {
    fetchAllDocuments();
  }, []);

  return (
    <>
      <div className="w-full">
        <div>
          <p className="h-[31px] lg:ml-9 ml-[4px] md:ml-2 mb-[5px] sm:text-[18px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-normal">
            STEP 2
          </p>
        </div>

        <div className="flex items-center ml-[4px] md:ml-1">
          <div>
            <BackButton
              onClick={() => {
                setShowFaqAuto(false);
                setFaqList([]);
              }}
            />
          </div>
          <h3 className="h-[39px] mt-2 sm:text-[18px] sm:leading-[29.18px] md:text-[28px] md:leading-[39.28px] font-medium">
            Frequently Asked Questions
          </h3>
          <div className="flex space-x-4 ml-auto">
            <div
              className="h-12 w-12 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
              onClick={openPopup1}
            >
              <FaRegFileAlt className="h-6 w-6" />
            </div>
            <div
              className="h-12 w-12 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
              onClick={openPopup2}
            >
              <RiLink className="h-6 w-6" />
            </div>
          </div>
        </div>
        {documentList?.length > 0 ? (
          isLoading?<div className="absolute  top-[55%] left-[60%]">
          <ImSpinner9 className="h-10 w-10 text-[#006BEA] animate-spin" />
        </div>:
          <FaqFillScreen
            documentList={documentList}
            deleteDocuments={(documentId: string) => deleteDocuments(documentId)}
            handleEditUrl={handleEditUrl}
          />
        ) : (
          <FaqEmptyScreen />
        )}

        <div className="fixed bottom-2 right-6 gap-2 md:gap-4 bg-[#1E1E1E]">
          <div className="flex gap-7  ">
            <Button
              isFormValid={true}
              btnText="Skip"
              textColor="#FFFFFF"
              backGroundColor=""
              borderColor="#006BEA"
              onClick={() => navigate('/account-setup/widgets')}
            />

            {documentList.length > 0 && (
              <Button
                isFormValid={true}
                btnText="Next"
                textColor="#FFFFFF"
                backGroundColor="#006BEA"
                borderColor="#006BEA"
                onClick={fetchGeneratedFAQ}
                disable={disable}
              />
            )}
          </div>
        </div>

        {isPopupOpenFile && (
          <PopupFile onClose={closePopup1} fetchAllDocuments={fetchAllDocuments} />
        )}
        {isPopupOpenUrl && (
          <PopupUrl
            onClose={closePopup2}
            fetchAllDocuments={fetchAllDocuments}
            editUrlPopupData={editUrlPopupData}
            closePopup2={closePopup2}
          />
        )}

        <ToastContainer />
        {disable && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FaqAuto;
