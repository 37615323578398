import { ImSpinner9 } from 'react-icons/im';

const Loader = () => {
  return (
    <div className="h-screen w-full flex">
      <div className="flex content-center justify-center items-center w-full">
        <ImSpinner9 className="h-10 w-10 text-[#006BEA] animate-spin" />
      </div>
    </div>
  );
};
export default Loader;
