import React from 'react';
import MatrixAILogo from '../assets/MatrixAI.png';

const MatrixAiLogo = () => {
  return (
    <div className="lg:mb-4 mb-1 flex items-center p-2">
      <img src={MatrixAILogo} alt="Logo" className="lg:w-[57px] w-10 mr-2" />
      <h2 className="lg:text-[40px] text-lg font-bold">Matrix AI</h2>
    </div>
  );
};

export default MatrixAiLogo;
