import { API_URL } from './Environment';

interface userInfo {
  name: string;
  email: string;
  companyName: string;
  contactNumber: string;
  employeeId: string;
  dateOfBirth: string;
  position: string;
  address: string;
}
interface updateUserInfo {
  uuid: string;
  name: string;
  email: string;
  companyName: string;
  contactNumber: string;
  employeeId: string;
  dateOfBirth?: string | null;
  position: string;
  address: string;
}

async function createUser(userInfo: userInfo, companyUuid: string) {
  const response = await fetch(API_URL + `users/create-user?companyUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(userInfo)
  });
  return response;
}

async function getUser(companyUuid: string) {
  const response = await fetch(API_URL + `users/get-user?companyUuid=${companyUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  const data = await response.json();
  return data;
}

async function updateUser(updateUserInfo: updateUserInfo, companyUuid: string) {
  const response = await fetch(API_URL + `users/update-user?companyUuid=${companyUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(updateUserInfo)
  });
  return response;
}
async function deleteUser(companyUuid: string, uuid: string) {
  const response = await fetch(API_URL + `users/delete-user?companyUuid=${companyUuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify({ uuid })
  });

  if (!response.ok) {
    throw new Error(`${response.statusText}`);
  }

  return response;
}

export { createUser, getUser, updateUser, deleteUser };
