import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from '../components/Authentication/Login';
import SignUp from '../components/Authentication/SignUp';
import ForgotPassword from '../components/Authentication/ForgotPassword';
import ResetPassword from '../components/Authentication/ResetPassword';

const AuthNavigation = () => {
  const authNavigation = [
    {
      path: '/',
      element: <Login />
    },
    {
      path: '/login/:uuid',
      element: <Login />
    },
    {
      path: '/signup',
      element: <SignUp />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    },
    {
      path: '/reset-password/:token',
      element: <ResetPassword />
    },
    
  ];
  return (
    <Routes>
      {authNavigation.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default AuthNavigation;
