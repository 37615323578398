import React, { useEffect, useState } from 'react';
import CreateWidgets from './CreateWidgets';
import { websiteRegex } from '../../../../function/Regex';
import { generateWidgets, getWidgetsInfo, updateWidgets } from '../../../../api/AccountSetup';
import ToastNotification from '../../../../common/ToastNotification';
import { useNavigate } from 'react-router-dom';
interface WidgesProps{
  isSettings?:boolean |undefined
}
const Widgets = (props:WidgesProps) => {
  const {isSettings}=props;
  const companyUuid = sessionStorage.getItem('companyUuid');

  const navigate = useNavigate();

  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [state, setState] = useState({
    website: '',
    websiteError: '',
    chatbotName: '',
    listOfThemes: ['#3572EF', '#98ABEE', '#7776B3', '#1D24CA', '#9290C3','#73EC8B','#FF7F3E','#6A1E55','#E5D9F2'],
    selectedTheme: '#3572EF'
  });
  const [widgetUUID, setWidgetUUID] = useState('');

  const handleState = (field: string, value: string) => {
    if (field === 'website') {
      setState({
        ...state,
        [field]: value,
        websiteError: !websiteRegex.test(value) ? 'Please enter valid URL' : ''
      });
    } else {
      setState({
        ...state,
        [field]: value
      });
    }
  };

  const handleCreateOrUpdateWidgets = async (e: any) => {
    e.preventDefault();
    try {
      setIsDisabled(true);
      let payload: any = {
        theme: state.selectedTheme,
        name: state.chatbotName,
        website: state.website
      };
      if (widgetUUID) {
        payload = { ...payload, uuid: widgetUUID };
      }
      const res = widgetUUID
        ? await updateWidgets(payload, companyUuid ? companyUuid : '')
        : await generateWidgets(payload, companyUuid ? companyUuid : '');
      if (res.message === 'Created') {
        ToastNotification({
          message: res.message,
          type: 'success'
        });
        setIsDisabled(false);
        setTimeout(() => {
          if(!isSettings)
          {

            navigate('/account-setup/integration');
          }
        }, 1500);
      } else {
        ToastNotification({
          message: 'Something went wrong',
          type: 'error'
        });
        setIsDisabled(false);
      }
    } catch {
      setIsDisabled(false);
    }
  };

  const fetchWidgetInfo = async () => {
    const res = await getWidgetsInfo(companyUuid ? companyUuid : '');
    if (res.message === 'Successful') {
      if (res.payload.uuid !== null) {
        setWidgetUUID(res.payload.uuid);
        // console.log(res.payload.theme.theme)
        setState({
          ...state,
          website: res.payload.widges.website,
          selectedTheme: res.payload.theme.theme,
          chatbotName: res.payload.widges.name
        });
      }
    }
  };

  useEffect(() => {
    fetchWidgetInfo();
  }, []);

  return (
    <CreateWidgets
      state={state}
      isSubmit={isSubmit}
      isDisabled={isDisabled}
      setIsSubmit={setIsSubmit}
      handleState={handleState}
      handleCreateOrUpdateWidgets={handleCreateOrUpdateWidgets}
      isSettings={isSettings}
    />
  );
};

export default Widgets;
