const ComplaintsCard = ({ info, navigatetocomplaints }: any) => {
  const VendorProfile = () => {
    return (
      <div className="flex justify-end p-3 relative">
        {info.usersWithUnassignedComplaints.slice(0, 3).map((value: any, index: number) => {
          const zIndex = 20 - index * 10;
          const right = 88 - index * 20;

          return (
            <div
              className={`w-8 h-8 bg-[#D9D9D9] rounded-full cursor-pointer items-center flex justify-center text-black font-regular text-lg font-bold absolute`}
              style={{ zIndex, right: `${right}px` }}
              key={index}
            >
              {value.fullName[0]?.toUpperCase() || 'A'}
            </div>
          );
        })}

        <label className="text-xl content-center font-bold text-white px-2 rounded-3xl">
          {info?.usersWithUnassignedComplaints?.length > 0
            ? `${info?.usersWithUnassignedComplaints?.length}+`
            : info?.usersWithUnassignedComplaints?.length}
        </label>
      </div>
    );
  };

  return (
    <div>
      {info?.raisedComplaints?.map((value: any, index: any) => (
        <div
          className="flex flex-col bg-[#616161] rounded-md my-2 p-1.5 gap-y-2 pl-2.5"
          key={index}
          onClick={navigatetocomplaints}
        >
          <label className="text-sm font-bold text-white">{value.title}</label>
          <label className="text-xs font-normal text-white text-ellipsis overflow-hidden whitespace-nowrap w-[98%] leading-3">
            {value.description}
          </label>
          {/* <div className="flex relative justify-end p-3">
            <img
              src={Profile1}
              alt="profile"
              className="w-[30px] h-[30px] rounded-3xl absolute z-20 right-[88px] bottom-1"
            />
            <img
              src={Profile2}
              alt="profile"
              className="w-[30px] h-[30px] rounded-3xl absolute z-10 right-[68px] bottom-1"
            />
            <img
              src={Profile3}
              alt="profile"
              className="w-[30px] h-[30px] rounded-3xl absolute z-0 right-[48px] bottom-1"
            />

            <div className="w-11 h-11 bg-[#D9D9D9] rounded-full cursor-pointer items-center flex justify-center text-black font-regular text-lg font-bold">
              {info.title[0]?.toUpperCase() || 'A'}
            </div>
          </div> */}

          {/* <label className="text-xs font-bold text-white">30 +</label> */}
          <VendorProfile />
        </div>
      ))}
    </div>
  );
};

export default ComplaintsCard;
