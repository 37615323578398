import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../../../common/Button';
import { BackButton } from '../../../../common/BackButton';
import IntegrationDataCard from '../../../client-setup/pages/account-integration/IntegrationDataCard';
import {
  getListOfIntegration,
  intiateOAuthForCRM,
  verifyTokenInfo
} from '../../../../api/AccountSetup';
import ToastNotification from '../../../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../../common/Loader';
interface IntegrationProps {
  isSettings?: boolean;
}
export const Integration = (props: IntegrationProps) => {
  const { isSettings } = props;
  const navigate = useNavigate();
  const companyUuid = sessionStorage.getItem('companyUuid');
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const [integrationList, setIntegrationList] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const handleGoBack = () => {
    navigate('/account-setup/widgets');
  };

  const handleMoveToDashbord = () => {
    navigate('/dashboard');
  };

  const fetchListOfIntegration = async () => {
    try {
      setShowLoading(true);
      const res = await getListOfIntegration(companyUuid ? companyUuid : '');
      if (res.payload) {
        setIntegrationList(res.payload.integrationPartyList);
      } else if (res.message) {
        ToastNotification({
          message: res.message,
          type: 'error'
        });
      }
      setShowLoading(false);
    } catch {}
  };

  const handleCRMConnect = async (partyUuid: string) => {
    try {
      sessionStorage.setItem('partyUuid', partyUuid);
      const resp = await intiateOAuthForCRM(partyUuid, companyUuid ? companyUuid : '');
      if (resp.message === 'success') {
        window.location.href = resp.payload.url;
      }
    } catch {}
  };

  const handleVerifyTokenInfo = async (code: string) => {
    try {
      setShowLoading(true);
      const integrationPartyUuid = sessionStorage.getItem('partyUuid');
      const resp = await verifyTokenInfo(
        integrationPartyUuid ? integrationPartyUuid : '',
        companyUuid ? companyUuid : '',
        code
      );
      if (resp.message === 'success') {
        fetchListOfIntegration();
      } else {
        setShowLoading(false);
      }
    } catch {}
  };

  useEffect(() => {
    fetchListOfIntegration();
  }, []);

  useEffect(() => {
    if (code) {
      handleVerifyTokenInfo(code);
    }
  }, [code]);

  return (
    <>
      <div className={`${isSettings ? '' : 'pt-3'} h-full w-full min-h-[600px]`}>
        {!isSettings && (
          <div>
            <h2 className="h-[31px] lg:ml-9 ml-[4px] md:ml-2 mb-[5px] sm:text-[18px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-normal">
              STEP 5
            </h2>
            <div className="flex ml-[4px] md:ml-1">
              <div className="mt-1">
                <BackButton onClick={handleGoBack} />
              </div>
              <h1 className="h-[39px] lg:ml-0 mt-4 md:mt-2 mb-[2px] sm:text-[18px] sm:leading-[29.18px] md:text-[28px] md:leading-[39.28px] font-medium">
                Integration
              </h1>
            </div>
          </div>
        )}

        {showLoading ? (
          <div className="absolute inset-0 left-[15%]">
            <Loader />
          </div>
        ) : (
          <div className="pl-6">
            <h1 className="text-[22px] font-medium mt-2  sm:ml-[10px]">Data Source</h1>
            <IntegrationDataCard
              integrationList={integrationList.filter((info: any) => info.type === 'DATA_SOURCE')}
            />
            <h1 className="text-[22px] font-medium mt-2 sm:ml-[4px] pl-1">CRM Integration</h1>
            <IntegrationDataCard
              integrationList={integrationList.filter((info: any) => info.type === 'CRM')}
              handleCRMConnect={handleCRMConnect}
            />
          </div>
        )}

        <div className="sm:static  flex justify-end bottom-4 right-6 gap-2 md:gap-4 ">
          {!isSettings && (
            <div className="mr-[10px] justify-between">
              <Button
                isFormValid={true}
                btnText="Skip"
                textColor="#FFFFFF"
                backGroundColor=""
                borderColor="#006BEA"
                onClick={handleMoveToDashbord}
              />
            </div>
          )}
          <div className={`${isSettings ? 'mr-4' : ''}`}>
            <Button
              isFormValid={true}
              btnText={`${isSettings ? 'Update' : 'Next'}`}
              textColor="#FFFFFF"
              backGroundColor="#006BEA"
              borderColor="#006BEA"
              onClick={!isSettings ? handleMoveToDashbord : undefined}
            />
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};
