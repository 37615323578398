import { LuTrash } from 'react-icons/lu';
import { MdOutlineStarPurple500 } from 'react-icons/md';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  info: any;
  index: number;
  deleteVendordata: (clientVendorUuid: string) => Promise<void>;
}

const SingleVendor = (props: IProps) => {
  const { info, index, deleteVendordata } = props;
  const navigate = useNavigate();

  const handleNavigateToClick = (uuid: string) => {
    navigate(`/vendors/update-vendor/${uuid}`);
  };
  return (
    <div
      className="bg-[#343434] rounded-2xl p-2.5 pb-5 flex flex-col self-start cursor-pointer"
      key={index}
      onClick={() => handleNavigateToClick(info.uuid)}
    >
      <div className="flex self-end">
        <LuTrash
          onClick={(e) => {
            e.stopPropagation();
            deleteVendordata(info.uuid);
          }}
          color="#FF4C4C"
        />
      </div>
      <div className="flex flex-col justify-center items-center gap-3">
        <div className="w-20 h-20 bg-[#D9D9D9] rounded-full cursor-pointer items-center flex justify-center text-black font-bold text-xl">
          {info.profile ? (
            <img
              src={info.profile}
              alt={info.name}
              className="w-full h-full object-cover rounded-full"
            />
          ) : (
            info.name?.charAt(0).toUpperCase()
          )}
        </div>
        <div className="flex flex-col justify-center items-center">
          <label className="font-bold text-lg text-white">{info.name}</label>
          <label className="font-regular text-base text-white">{info.position}</label>
        </div>
        <ul className="flex space-x-4 justify-center">
          {new Array(5).fill(1).map((_, idx) => (
            <MdOutlineStarPurple500 key={idx} color={idx < info.rating ? '#FFF04C' : '#D9D9D9'} />
          ))}
        </ul>
        <div className="flex items-center gap-5">
          <div className="flex flex-col justify-center items-center text-white text-sm font-normal">
            <label>Complaints</label>
            <label className="font-bold">{info.complaint}</label>
          </div>
          <div className="flex flex-col justify-center items-center text-white text-sm font-normal">
            <label>Solved</label>
            <label className="font-bold">{info.solved}</label>
          </div>
          <div className="flex flex-col justify-center items-center text-white text-sm font-normal">
            <label>Pending</label>
            <label className="font-bold">{info.pending}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVendor;
