import { API_URL } from './Environment';
interface assignUserData {
  complaintUuid: string;
  userUuid: string;
}
interface VendorListResponse {
  payload: {
    count: number;
    vendor: {
      uuid: string;
      vendorUuid: string;
      name: string;
      position: string;
      rating: number | null;
      profile: string | null;
      complaint: number;
      solved: number;
      pending: number;
    }[];
  };
  message: string;
}
interface UpdateComplaintStatus {
  complaintUuid: string;
  status: string;
}

export interface IVendorAssigned {
  vendorUuid: string;
  companyUuid: string;
  complaintUuid: string;
}

async function getComplaint(
  companyUuid: string,
  type: string,
  page: Number,
  limit: Number,
  from: string,
  to: string
) {
  const response = await fetch(
    API_URL +
      `/complaint?companyUuid=${companyUuid}&type=${type || 'Sort'}&page=${page}&limit=${limit}&from=${from || ''}&to=${to || ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );
  const data = await response.json();
  return data;
}
async function assignDataPopup(companyUuid: string) {
  const response = await fetch(API_URL + `complaint/user-list?companyUuid=${companyUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch vendors: ${response.statusText}`);
  }

  return response;
}

async function assignUser(userDate: assignUserData) {
  const response = await fetch(API_URL + `complaint/assign-user`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(userDate)
  });
  return response;
}

async function getVendorList(companyUuid: string, page: number, limit: number) {
  const response = await fetch(
    `${API_URL}vendor?companyUuid=${companyUuid}&page=${page}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );

  if (!response.ok) {
    throw new Error(`Error fetching vendor list: ${response.statusText}`);
  }

  const vendorList: VendorListResponse = await response.json();
  return vendorList;
}

async function vendorAssigned(vendorDetails: IVendorAssigned) {
  const response = await fetch(API_URL + 'complaint/assign-vendor', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(vendorDetails)
  });
  return response;
}
async function updateComplaintStatus(statusDetails: UpdateComplaintStatus, companyUuid: string) {
  const response = await fetch(API_URL + `complaint/update-status?companyUuid=${companyUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(statusDetails)
  });
  return response;
}

export {
  assignDataPopup,
  getComplaint,
  assignUser,
  getVendorList,
  vendorAssigned,
  updateComplaintStatus
};
