import { createFaq, updateFaq } from '../../../../../api/AccountSetup';
import ToastNotification from '../../../../../common/ToastNotification';
const companyUuid = sessionStorage.getItem('companyUuid');
const faqAddORUpdate = async ({
  questionsList,
  setQuestionsList,
  faqList,
  navigate,
  setDisable,
  isSettings = false,
  fetchAllFaq
}: any) => {
  setDisable(true);
  const newQuestionList = questionsList.map((info: any) => {
    return {
      question: info.question,
      answer: info.answer
    };
  });
  const updateQuestionList = questionsList.filter(
    (ques: any) => 'uuid' in ques && typeof ques.uuid === 'string' && ques.uuid.trim() !== ''
  );
  const faqInfo = [
    {
      uuid: null,
      faq: newQuestionList
    }
  ];
  const updateFaqInfo = {
    document: questionsList
  };

  console.log('updateFaqInfo', updateFaqInfo);
  if (questionsList.length > 0) {
    if (updateQuestionList.length > 0) {
      try {
        const response = await updateFaq(updateFaqInfo, companyUuid ? companyUuid : '');
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });

          setDisable(false);
          if (!isSettings) {
            setTimeout(() => {
              navigate('/account-setup/widgets');
              setQuestionsList([]);
            }, 1500);
          } else {
            fetchAllFaq();
          }
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
          setDisable(false);
        }
      } catch (error: any) {
        // console.log('error during faqinfo post', error);
      }
    } else if (newQuestionList.length > 0) {
      try {
        const faqPayload = {
          document: faqList.length > 0 ? faqList : faqInfo
        };
        const response = await createFaq(faqPayload, companyUuid ? companyUuid : '');
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });
          setDisable(false);
          if (!isSettings) {
            setTimeout(() => {
              navigate('/account-setup/widgets');
              setQuestionsList([]);
            }, 1500);
          } else {
            fetchAllFaq();
          }
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
          setDisable(false);
        }
      } catch (error: any) {
        // console.log('error during faqinfo post', error);
      }
    }
  } else {
    ToastNotification({
      message: 'Please enter valid data',
      type: 'error'
    });
    setDisable(false);
  }
};
export default faqAddORUpdate;
