import React, { useEffect } from 'react';

interface CommonPopupProps {
  width?: string;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
  isFaqPopup?:boolean;
}

const CommonPopup: React.FC<CommonPopupProps> = ({ width, title, children, footer, onClose,isFaqPopup }) => {
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (event.target.classList.contains('backdrop')) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className=" bg-black bg-opacity-60 flex justify-center items-center z-50 backdrop inset-0 fixed">
      <div className={`bg-[#1e1e1e] p-6 rounded-lg ${width ? width : 'md:w-1/2'}`}>
        {title && <h2 className="text-xl text-white font-semibold mb-4">{title}</h2>}
        <div className={`${isFaqPopup ? 'mb-2':"mb-6"}`}>{children}</div>
        {footer && <div className="flex justify-end">{footer}</div>}
      </div>
    </div>
  );
};

export default CommonPopup;
