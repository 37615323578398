import React, { useState, useRef } from 'react';
import Button from '../../../../common/Button';
import InputBox from '../../../../common/InputBox';
import { IoCopyOutline } from 'react-icons/io5';
import { BackButton } from '../../../../common/BackButton';
import { useNavigate } from 'react-router-dom';

interface widgetsubmitProps {
  isDisabled: boolean;
  handleCreateOrUpdateWidgets: any;
  isSettings?: boolean | undefined;
}

const WidgetsSubmit = (props: widgetsubmitProps) => {
  const { isDisabled, handleCreateOrUpdateWidgets, isSettings } = props;

  const navigate = useNavigate();
  const paragraphRef = useRef<HTMLParagraphElement>(null);

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (paragraphRef.current) {
      const textToCopy = paragraphRef.current.textContent || '';
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  };

  return (
    <div className="px-5 mt-5">
      <h2 className="mb-[15px] sm:text-[18px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-medium">
        Lorem Ipsum
      </h2>
      <div
        onClick={handleCopy}
        className="flow-root w-full bg-[#343434]  rounded-[10px] md:p-4 p-2   sm:text-[9px] sm:leading-[17.86px] md:text-[18px] md:leading-[21.9px] font-light"
      >
        <button className="flex float-right items-center  bg-[#626262] py-[2px] px-[5px] rounded-[3px] gap-[10px] h-[24px] text-[#FFFFFF] text-[10px] leading-[10px]  md:text-[14px]  md:leading-[19.64px] font-normal">
          <IoCopyOutline />
          Copy
        </button>
        <p ref={paragraphRef} className="lg:w-[569px] w-full">
          {`<meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Website with Tracking Code</title>
                  <script>
                  // Tracking function to send data to your server
                  function trackEvent(eventName, eventData = {}) {
                  fetch("https://your-server-endpoint.com/track", {
                method: "POST",`}
        </p>
      </div>
      {copied && (
        <span className="absolute left-[65%] md:left-[56%] lg:left-[59%] bottom-10 transform -translate-x-1/2 bg-[#626262] text-white text-[10px] md:text-[16px] py-1 px-2 rounded animate-popup">
          Copied to clipboard
        </span>
      )}
      <div className="flex justify-end gap-2 md:gap-4 lg:h-[132px] h-[115px] lg:mb-3">
        {!isSettings && (
          <Button
            isFormValid={true}
            btnText="Skip"
            textColor="#FFFFFF"
            backGroundColor="#1E1E1E"
            borderColor="#006BEA"
            onClick={() => navigate('/account-setup/integration')}
          />
        )}
        <Button
          disable={isDisabled}
          isFormValid={true}
          btnText={`${isSettings ? 'Update' : 'Next'}`}
          textColor="#FFFFFF"
          backGroundColor="#006BEA"
          borderColor="#006BEA"
          onClick={handleCreateOrUpdateWidgets}
        />
      </div>
      {isDisabled && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default WidgetsSubmit;
