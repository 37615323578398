import React from 'react';
interface IProps {
  btnText: string;
  isFormValid: boolean;
  textColor: string;
  backGroundColor: string;
  borderColor?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  disable?: boolean;
}

const Button = (props: IProps) => {
  const { isFormValid, btnText, textColor, backGroundColor, borderColor, onClick, disable } = props;
  return (
    <div>
      <button
        type="submit"
        className=" mt-10 mb-6 flex justify-center items-center mx-auto w-auto md:w-auto md:justify-center md:items-center md:mx-auto rounded-[7px] py-2 px-[22px] text-xl font-bold shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        style={{
          backgroundColor: disable ? '#cccccc' : !isFormValid ? backGroundColor : backGroundColor,
          color: textColor,
          cursor: disable ? 'not-allowed' : !isFormValid ? 'pointer' : 'pointer',
          border: borderColor ? (isFormValid ? `2px solid ${borderColor}` : 'none') : 'none'
        }}
        disabled={disable}
        onClick={onClick}
      >
        {btnText}
      </button>
    </div>
  );
};

export default Button;
