import EfficiencyTable from './EfficiencyTable';

const VendorEfficiency = ({ vendor }: any) => {
  return (
    <div className="flex flex-col gap-y-3.5 bg-[#343434] rounded-lg pt-2.5 p-4 pb-6">
      <label className="text-lg font-normal text-white">Vendor Efficiency</label>
      <EfficiencyTable vendors={vendor} />
    </div>
  );
};

export default VendorEfficiency;
