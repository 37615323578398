import React, { Fragment, useEffect, useState } from 'react';
import { IoFilterSharp } from 'react-icons/io5';
import NotificationList from './NotificationList';
import SpecificNotificationDetails from './SpecificNotificationDetails';
import ToolTip from '../common/ToolTip';
import { notifyDashboard, updateNotification } from '../api/Notification';
import ToastNotification from '../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import { useSocket } from '../Contexts/SocketContext';
import Loader from '../common/Loader';
import ReactPaginate from 'react-paginate';

interface NotificationType {
  message: string;
  uuid: string;
  createdTime: string;
}

interface ApiResponse {
  message: string;
  payload: {
    notification: {
      message: string;
      uuid: string;
      createdAt: string;
    }[];
  };
}

const Notification: React.FC = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [allTickets, setAllTickets] = useState<NotificationType[]>([]);
  const [tickets, setTickets] = useState<NotificationType[]>([]);
  const [selectedTicketIdx, setSelectedTicketIdx] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const { socketInstance } = useSocket();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 6;
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const fetchNotifications = async () => {
    try {
      setShowLoading(true);
      const response = await notifyDashboard();

      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }

      const data: ApiResponse = await response.json();

      const notifications: NotificationType[] = data.payload.notification.map((notif) => ({
        message: notif.message,
        uuid: notif.uuid,
        createdTime: calculateRelativeTime(notif.createdAt)
      }));

      setAllTickets(notifications);
      setTotalItems(notifications.length);
      setShowLoading(false);
      setCurrentPage(1);
    } catch (error) {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on('notifications', (notifications: any) => {
        const mappedNotifications: NotificationType[] = notifications.map((notification: any) => ({
          message: notification.message,
          uuid: notification.uuid,
          createdTime: calculateRelativeTime(notification.createdAt)
        }));
        setAllTickets(mappedNotifications);
      });

      socketInstance.on('updateNotifications', (notification: any) => {
        socketInstance.emit('getNotifications');
      });

      return () => {
        socketInstance.off('notifications');
        socketInstance.off('updateNotifications');
      };
    }
  }, [socketInstance]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    setTickets(allTickets.slice(startIdx, endIdx));
  }, [currentPage, allTickets]);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const markAsRead = async (uuid: any) => {
    try {
      const response = await updateNotification(uuid);

      if (response.ok) {
        const data = await response.json();
        ToastNotification({
          message: data.message
        });
        fetchNotifications();
      }
    } catch (error: any) {
      //console.error('Error updating notification:', error);
    }
  };

  const calculateRelativeTime = (createdTime: string): string => {
    try {
      const now = new Date();
      const createdDate = new Date(createdTime);

      if (isNaN(createdDate.getTime())) {
        throw new Error('Invalid date format');
      }

      const diffMs = now.getTime() - createdDate.getTime();

      if (diffMs < 0) {
        return 'Future date not allowed';
      }

      if (diffMs < 60000) {
        const diffSeconds = Math.floor(diffMs / 1000);
        return `${diffSeconds} sec${diffSeconds !== 1 ? 's' : ''} ago`;
      }

      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      if (diffMinutes < 60) return `${diffMinutes} min${diffMinutes !== 1 ? 's' : ''} ago`;

      const diffHours = Math.floor(diffMinutes / 60);
      if (diffHours < 24) return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;

      const diffDays = Math.floor(diffHours / 24);
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    } catch (error) {
      return 'Unknown time';
    }
  };

  return (
    <>
      {showLoading ? (
        <div className="flex  justify-center items-center absolute inset-0">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {totalItems > 0 ? (
            <div className="bg-[#1E1E1E] mt-6 py-5 w-full lg:px-5 px-2 overflow-y-auto xl:overflow-hidden">
              <div className="flex flex-col xl:flex-row gap-2.5 pb-24 xl:pb-0">
                <div className="w-full xl:w-[50%] bg-[#343434] rounded-lg pr-2 pt-2 pb-2 overflow-y-auto max-h-[700px]">
                  <NotificationList
                    tickets={tickets || []}
                    selectedTicketIdx={selectedTicketIdx}
                    setSelectedTicketIdx={setSelectedTicketIdx}
                    markAsRead={markAsRead}
                  />
                </div>
                <div className="w-full xl:w-[50%] bg-[#343434] rounded-lg p-2">
                  <SpecificNotificationDetails
                    tickets={tickets || []}
                    selectedTicketIdx={selectedTicketIdx}
                  />
                </div>
              </div>
              <div className="mt-1">
                {totalItems > 6 && (
                  <ReactPaginate
                    previousLabel={
                      <div className="flex items-center justify-center w-8 h-8 border rounded border-white text-white">
                        &lt;
                      </div>
                    }
                    nextLabel={
                      <div className="flex items-center justify-center w-8 h-8 border rounded border-white text-white">
                        &gt;
                      </div>
                    }
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName="flex justify-center items-center gap-3"
                    pageLinkClassName="cursor-pointer"
                    activeLinkClassName="text-blue-500 font-bold"
                    disabledClassName="opacity-50 cursor-not-allowed"
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-3/6 w-full text-white text-lg font-bold">
              No record found
            </div>
          )}
        </Fragment>
      )}
      <ToastContainer />
    </>
  );
};

export default Notification;
