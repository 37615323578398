import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../common/Button';
import { BackButton } from '../../../../../common/BackButton';
import RichTextBox from './RichTextBox';
import { useNavigate } from 'react-router-dom';
import faqAddORUpdate from './FaqAddOrUpdate';
import { FaPlus } from 'react-icons/fa';
import Loader from '../../../../../common/Loader';
import { ImSpinner9 } from 'react-icons/im';

interface IProps {
  setShowFaqManual: any;
  questionsList: { question: string; answer: string; uuid?: string | null }[];
  faqList: { uuid: string | null; faq: { question: string; answer: string }[] }[];
  setQuestionsList: any;
  disable: any;
  setDisable: any;
  fetchAllFaq: any;
  setFaqList: any;
  isLoading:boolean;
  handleDeleteFaq: (index: number, faqUUID: string) => void;
}
const FaqManual = (props: IProps) => {
  const {
    questionsList,
    faqList,
    setQuestionsList,
    setShowFaqManual,
    disable,
    setDisable,
    fetchAllFaq,
    setFaqList,
    isLoading,
    handleDeleteFaq
  } = props;

  const navigate = useNavigate();
  const optimizedQuestionsList = useMemo(() => questionsList, [questionsList]);
  const [isAddOrEditPopUpOpen, setIsAddOrEditPopUpOpen] = useState(false);

  const faqPost = () => {
    faqAddORUpdate({ questionsList, setQuestionsList, faqList, navigate, setDisable });
  };

  useEffect(() => {
    if (faqList.length === 0) {
      fetchAllFaq();
    }
  }, []);

  return (
    <div className="w-full">
      <div className="">
        <p className="h-[31px] lg:ml-9 ml-[4px] md:ml-2 mb-[5px] sm:text-[18px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-normal">
          STEP 2
        </p>
      </div>
      <div className="flex justify-between mb-10">
        <div className="flex items-center ml-[4px] md:ml-1">
          <div>
            <BackButton
              onClick={() => {
                setShowFaqManual(false);
                setFaqList([]);
              }}
            />
          </div>
          <h3 className="h-[39px] mt-2 sm:text-[18px] sm:leading-[29.18px] md:text-[28px] md:leading-[39.28px] font-medium">
            Frequently Asked Questions
          </h3>
        </div>
        <div
          className="h-12 w-12 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
          onClick={() => setIsAddOrEditPopUpOpen(true)}
        >
          <FaPlus className="h-6 w-6" />
        </div>
      </div>
      {isLoading?<div className="absolute  top-[60%] left-[55%]">
        <ImSpinner9 className="h-10 w-10 text-[#006BEA] animate-spin" />
      </div>:  <RichTextBox
        questionsList={optimizedQuestionsList}
        setQuestionsList={setQuestionsList}
        handleDeleteFaq={handleDeleteFaq}
        setIsAddOrEditPopUpOpen={setIsAddOrEditPopUpOpen}
        isAddOrEditPopUpOpen={isAddOrEditPopUpOpen}
      />}
   

      <div className="fixed bottom-2 right-6 gap-2 md:gap-4">
        <div className="flex gap-7">
          <Button
            isFormValid={true}
            btnText="Skip"
            textColor="#FFFFFF"
            backGroundColor="#1E1E1E"
            borderColor="#006BEA"
            onClick={() => navigate('/account-setup/widgets')}
          />
          <Button
            isFormValid={true}
            btnText="Next"
            textColor="#FFFFFF"
            backGroundColor="#006BEA"
            borderColor="#006BEA"
            onClick={() => faqPost()}
            disable={disable}
          />
        </div>
      </div>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
};
export default FaqManual;
