import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DashboardLayout from '../components/dashboard/DashboardLayout';
import Dashboard from '../components/dashboard/Dashboard';
import Complaints from '../components/complaints/Complaints';
import Vendors from '../components/vendors/Vendors';
import CreateOrUpdateVendor from '../components/vendors/CreateOrUpdateVendor';
import Notification from '../notifications/Notification';

const SidebarMenuNavigation = () => {
  const sidebarMenuNavigation = [
    {
      path: '/dashboard',
      element: (
        <DashboardLayout>
          <Dashboard />
        </DashboardLayout>
      )
    },
    {
      path: '/complaints',
      element: (
        <DashboardLayout>
          <Complaints />
        </DashboardLayout>
      )
    },
    {
      path: '/vendors',
      element: (
        <DashboardLayout>
          <Vendors />
        </DashboardLayout>
      )
    },
    {
      path: '/vendors/add-vendor',
      element: (
        <DashboardLayout>
          <CreateOrUpdateVendor />
        </DashboardLayout>
      )
    },
    {
      path: '/vendors/update-vendor/:id',
      element: (
        <DashboardLayout>
          <CreateOrUpdateVendor />
        </DashboardLayout>
      )
    },
    {
      path: '/notifications',
      element: (
        <DashboardLayout>
          <Notification />
        </DashboardLayout>
      )
    }
  ];

  return (
    <Routes>
      {sidebarMenuNavigation.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default SidebarMenuNavigation;
