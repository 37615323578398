import { LuTrash } from 'react-icons/lu';

interface Notification {
  message: string;
  uuid: string;
  createdTime: string;
}

interface IProps {
  tickets: Notification[]; // Assuming each ticket is a structured object
  selectedTicketIdx: number;
}

const SpecificNotificationDetails = ({ tickets, selectedTicketIdx }: IProps) => {
  const selectedTicket = tickets[selectedTicketIdx];

  // Handle the case when no ticket is selected or `selectedTicketIdx` is invalid
  if (!selectedTicket) {
    return (
      <div className="flex items-center justify-center h-full">
        <label className="text-lg text-white font-bold">
          Select a notification to view its details.
        </label>
      </div>
    );
  }

  return (
    // xl:h-[calc(100vh-234px)]. it was before 264px.
    <div className="relative xl:h-[calc(100vh-234px)] flex flex-col gap-2.5 xl:overflow-y-auto pr-2">
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <div className="flex gap-5">
          <label className="text-lg text-white pl-[10px] font-bold">{selectedTicket.message}</label>
        </div>
        <LuTrash color="#FF4C4C" className="w-[26px] h-[26px]" />
      </div>

      {/* Time Section */}
      <div className="absolute bottom-0 right-0 pr-2 ">
        <label className="text-xs font-bold text-[#D7D7D7]">{selectedTicket.createdTime}</label>
      </div>

      {/* Message Section */}
      {/* <div className="pl-[10px] mt-4">
        <label className="text-[18px] text-white font-normal">{selectedTicket.message}</label>
      </div> */}
    </div>
  );
};

export default SpecificNotificationDetails;
