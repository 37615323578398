import React from 'react';
import { FaApple, FaFacebook, FaGoogle, FaMicrosoft } from 'react-icons/fa';

const Icon = () => {
  return (
    <div className=" flex flex-row justify-self-center md:gap-[60px] sm:gap-[40px] gap-[30px] underline md:text-[24px] text-[20px]">
      <div>
        <FaGoogle />
      </div>
      <div>
        <FaApple />
      </div>
      <div>
        <FaFacebook />
      </div>
      <div>
        <FaMicrosoft />
      </div>
    </div>
  );
};

export default Icon;
