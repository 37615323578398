import React from 'react';
import { useLocation,Link } from 'react-router-dom';

interface sidebarItemProps {
  image: React.ReactNode;
  label: string;
  isActive?: boolean;
  hasImage?: boolean;
  handleSidebarItemsClick: (id: number) => void;
  id: number;
  url:string
}

const DashboardSidebarItem = (props: sidebarItemProps) => {
  const { image, label, url,isActive = false, handleSidebarItemsClick, id } = props;
  const location = useLocation();

  return (
    // <Link to={url}>
    <Link
      className={`flex items-center gap-6 p-2 rounded-md lg:w-[246px]  lg:h-[47px] px-[18px] py-[10px] cursor-pointer ${
        location.pathname.startsWith(url) ? 'bg-[#FFFFFF]' : ''
      } `}
      onClick={() => handleSidebarItemsClick(id)}
     to={url}
    >
      <div className="flex items-center justify-center  mx-auto lg:mx-0 ">
        {typeof image === 'string' && <img src={image} className="lg:w-[24px] lg:h-[24px] w-[15px] h-[15px]" alt={label}/>}
      </div>

      <span
        className={`font-normal text-[24px] ${ location.pathname.startsWith(url)   ? 'text-[#006BEA]' : 'text-[#FFFFFF]'}  hidden lg:block`}
      >
        {label}
      </span>
    </Link>
    
  );
};

export default DashboardSidebarItem;
