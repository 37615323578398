import React, { useEffect, useState } from 'react';
import InputBox from '../../common/InputBox';
import { validateForm } from '../../function/FormValidation';
import { phoneNumberRangeRegex } from '../../function/Regex';
import Button from '../../common/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BackButton } from '../../common/BackButton';
import { createVendor } from '../../api/Vendor';
import ToastNotification from '../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import { getVendor, updateVendor } from '../../api/Vendor';

function CreateOrUpdateVendor() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const companyUuid = sessionStorage.getItem('companyUuid');
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const vendorData = await getVendor(id ? id : '');
        const vendorDetail = vendorData?.payload?.vendorDetail;
        // console.log(vendorDetail);
        if (vendorDetail) {
          setFormData({
            name: vendorDetail?.fullName || '',
            email: vendorDetail?.email || '',
            companyName: vendorDetail?.companyName || '',
            contactNumber: vendorDetail?.phone || '',
            employeeId: vendorDetail?.employeeId || '',
            dateOfBirth: vendorDetail?.dateOfBirth || '',
            position: vendorDetail?.position || '',
            address: vendorDetail?.address || ''
          });
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
        setErrors((prev) => ({ ...prev, fetch: 'Failed to fetch company details.' }));
      }
    };

    fetchCompanyDetails();
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    contactNumber: '',
    employeeId: '',
    dateOfBirth: '',
    position: '',
    address: ''
  });
  const navigates = useNavigate();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disable, setDisable] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };
  const handleUpdateVendorProfile = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
      | undefined
  ) => {
    e?.preventDefault();
    const { isValid, errors } = validateForm(formData, [
      'name',
      'email',
      'companyName',
      'contactNumber',
      'employeeId',
      'dateOfBirth',
      'position',
      'address'
    ]);

    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      if (location.pathname.startsWith('/vendors/update-vendor')) {
        try {
          const response = await updateVendor(
            {
              fullName: formData.name || '',
              email: formData.email || '',
              companyName: formData.companyName || '',
              phoneNumber: formData.contactNumber || '',
              employeeID: formData.employeeId || '',
              dateOfBirth: formData.dateOfBirth || null,
              position: formData.position || '',
              address: formData.address || ''
            },
            companyUuid ? companyUuid : '',
            id ? id : ''
          );
          if (response.ok) {
            const data = await response.json();
            ToastNotification({
              message: data.message
            });
            setTimeout(() => {
              navigate('/vendors');
              setDisable(false);
            }, 1500);
          } else {
            const errorData = await response.json();
            ToastNotification({
              message: errorData.message,
              type: 'warning'
            });
            setDisable(false);
          }
        } catch (error) {
          setDisable(true);
        }
      } else {
        try {
          // Call the signup API
          const response = await createVendor(
            {
              fullName: formData.name || '',
              email: formData.email || '',
              companyName: formData.companyName || '',
              phoneNumber: formData.contactNumber || '',
              employeeID: formData.employeeId || '',
              dateOfBirth: formData.dateOfBirth || null,
              position: formData.position || '',
              address: formData.address || ''
            },
            companyUuid ? companyUuid : ''
          );
          // Check if the API response is successful
          if (response.ok) {
            const data = await response.json();
            ToastNotification({
              message: data.message
            });
            setTimeout(() => {
              navigate('/vendors');
              setFormData({
                name: '',
                email: '',
                companyName: '',
                contactNumber: '',
                employeeId: '',
                dateOfBirth: '',
                position: '',
                address: ''
              });
              setDisable(false);
            }, 1500);
          } else {
            const errorData = await response.json();
            ToastNotification({
              message: errorData.message,
              type: 'warning'
            });
            setDisable(false);
          }
        } catch (error) {
          console.log('Error during Creating vendor:', error);
        }
      }
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target && target.type === 'date') {
      target.showPicker();
    }
  };

  const handleGoBack = () => {
    navigates('/vendors');
  };

  return (
    <div className="flex flex-col flex-1 bg-[#1E1E1E] h-[calc(100vh-90px)] overflow-y-auto">
      <div className="flex pr-5">
        <div className="">
          <BackButton onClick={handleGoBack} />
        </div>
        <h1 className="h-[26px] px-2 lg:ml-0 mt-4 md:mt-3 mb-[27px] sm:text-[18px] sm:leading-[29.18px] md:text-[24px] md:leading-[25.78px] font-medium">
          Vendors
        </h1>
      </div>
      <form
        className="flex flex-col justify-between "
        onSubmit={handleUpdateVendorProfile}
        method="Post"
      >
        <div className="space-y-0.5 pr-4 px-5">
          {' '}
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-1 text-[22px] font-medium text-light w-[190px] ps-5">Name</label>
            <div className=" flex-grow ">
              <InputBox
                name="name"
                type="text"
                value={formData.name}
                placeholder="Enter Your Name"
                handleChange={handleChange}
                error={errors.name}
              />
            </div>
          </div>
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-0 text-[22px] font-medium text-light w-[190px] ps-5">Email</label>
            <div className="flex-grow ">
              <InputBox
                name="email"
                type="text"
                value={formData.email}
                placeholder="Enter Your Email"
                handleChange={handleChange}
                error={errors.email}
              />
            </div>
          </div>
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-0 text-[22px] font-medium text-light w-[190px] ps-5">
              Company Name
            </label>
            <div className="flex-grow ">
              <InputBox
                name="companyName"
                type="text"
                value={formData.companyName}
                placeholder="Enter Your Company Name"
                handleChange={handleChange}
                error={errors.companyName}
              />
            </div>
          </div>
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-0 text-[22px] font-medium text-light w-[190px] ps-5">
              Contact Number
            </label>
            <div className="flex-grow ">
              <InputBox
                name="contactNumber"
                type="text"
                value={formData.contactNumber}
                placeholder="Enter Your Phone Number"
                error={errors.contactNumber}
                handleChange={(e) => {
                  const value = e.target.value;
                  if (phoneNumberRangeRegex.test(value)) {
                    handleChange(e);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-0 text-[22px] font-medium text-light w-[190px] ps-5">
              Employee ID
            </label>
            <div className="flex-grow ">
              <InputBox
                name="employeeId"
                type="text"
                value={formData.employeeId}
                placeholder="Enter Your Employee Id"
                handleChange={handleChange}
                error={errors.employeeId}
              />
            </div>
          </div>
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-0 text-[22px] font-medium text-light w-[190px] ps-5">D.O.B</label>
            <div className="flex-grow">
              <InputBox
                name="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                placeholder="D.O.B"
                handleChange={handleChange}
                error={errors.dateOfBirth}
                onclick={handleClick}
              />
            </div>
          </div>
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-0 text-[22px] font-medium text-light w-[190px] ps-5">
              Position
            </label>
            <div className="flex-grow ">
              <InputBox
                name="position"
                type="text"
                value={formData.position}
                placeholder="Enter Your Position"
                handleChange={handleChange}
                error={errors.position}
              />
            </div>
          </div>
          <div className="flex flex-wrap space-x-16">
            <label className="flex-shrink-0 text-[22px] font-medium text-light w-[190px] ps-5">Address</label>
            <div className="flex-grow ">
              <InputBox
                name="address"
                type="text"
                value={formData.address}
                placeholder="Enter Your Address"
                handleChange={handleChange}
                error={errors.address}
              />
            </div>
          </div>
        </div>

        <ToastContainer />
      </form>
      <div className="flex  justify-end items-end mt-auto  px-5 lg:mb-10">
        <Button
          isFormValid={true}
          btnText={`${location.pathname.startsWith('/vendors/update-vendor') ? 'Update' : 'Create'}`}
          disable={disable}
          textColor="#FFFFFF"
          backGroundColor="#006BEA"
          onClick={(event) => handleUpdateVendorProfile(event)}
        />
      </div>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default CreateOrUpdateVendor;
