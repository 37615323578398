import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface Iprops {
  showPassword: boolean;
  togglePasswordVisibility: any;
}
const EyeButton = (props: Iprops) => {
  const { showPassword, togglePasswordVisibility } = props;
  return (
    <div>
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute right-2 top-3  text-[#696969]"
      >
        {showPassword ? <FaEye size={25} /> : <FaEyeSlash size={25} />}
      </button>
    </div>
  );
};

export default EyeButton;
