import { useEffect, useState } from 'react';
import Loader from '../../common/Loader';
import StatusCardList from './complaint-status-card/StatusCardList';
import ComplaintsGraph from './complaints-graph/ComplaintsGraph';
import ComplaintsRaising from './complaints-raising/ComplaintsRaising';
import EngagementAnalysis from './engagement-analysis/EngagementAnalysis';
import VendorEfficiency from './vendor-efficiency/VendorEfficiency';
import { fetchDashboardInfo } from '../../api/DashBoard';
import Helper from '../../function/Helper';
import ToastNotification from '../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import { getUser } from '../../api/SettingUser';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState('yearly');
  const [selectedButton, setSelectedButton] = useState('');
  const [allUser, setAllUser] = useState<any>();

  const getDateRange = (date: string) => {
    switch (date) {
      case 'monthly':
        return { type: 'monthly', from: Helper.startOfMonth, to: Helper.endOfMonth };
      case 'weekly':
        return { type: 'weekly', from: Helper.startOfWeek, to: Helper.endOfWeek };
      case 'yearly1':
        return { type: 'yearly', from: Helper.previousStartYear, to: Helper.previousEndYear };
      case 'yearly2':
        return { type: 'yearly', from: Helper.previousTwoStartYear, to: Helper.previousTwoEndYear };
      default:
        return { type: 'yearly', from: Helper.startOfYear, to: Helper.endOfYear };
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const companyUuid = sessionStorage.getItem('companyUuid') || '';
      const { type, from, to } = getDateRange(date);
      const data = await fetchDashboardInfo(companyUuid, type, from, to);
      const allUsers = await getUser(companyUuid ? companyUuid : '');
      setAllUser(allUsers);
      setDashboardData(data.payload);
    } catch (err: any) {
      ToastNotification({
        message: err,
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [date]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="bg-[#1E1E1E] h-[calc(100vh-110px)] py-5 flex flex-col gap-y-5 w-full overflow-y-auto lg:px-5 px-2">
        <StatusCardList dashboardData={dashboardData} />

        <div className="flex flex-col xl:flex-row gap-5">
          <div className="w-full xl:w-[65%]">
            <ComplaintsGraph
              complaintsInfo={dashboardData?.complaints}
              date={date}
              setDate={setDate}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
            />
          </div>
          <div className="w-full xl:w-[35%]">
            <ComplaintsRaising complaintRaise={dashboardData?.complaintRaising} />
          </div>
        </div>

        <div className="flex flex-col xl:flex-row gap-5">
          <div className="w-full xl:w-[65%]">
            <VendorEfficiency vendor={dashboardData?.vendor} />
          </div>
          <div className="w-full xl:w-[35%]">
            <EngagementAnalysis
              engagement={dashboardData?.userLogInDetails}
              allUser={allUser?.payload?.result}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
