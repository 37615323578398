import { FaRegFileAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import { RiLink } from 'react-icons/ri';
import React from 'react';

interface IProps {
  documentList: {
    uuid: string;
    type: string;
    name: string;
  }[];
  deleteDocuments: any;
  handleEditUrl:any;
}

const FaqFillScreen = (props: IProps) => {
  const { documentList, deleteDocuments,handleEditUrl } = props;

  return (
    <div className="lg:ml-9 ml-[4px] md:ml-2">
      <div>
        <div className="mt-10">
          <p className="md:font-medium font-semibold text-xl md:text-[22px] leading-[30.86px]">
            Details
          </p>
        </div>
        {/* <div className="md:flex md:gap-[54px]"> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 pb-4">

          {documentList.map((info: any) => (
            <div className="mt-5" key={info.uuid}>
              <div className="w-40 h-40 border-[3px] rounded-lg border-[#006BEA]">
                <div className="flex gap-4 justify-end mr-2 mt-2 left-1 cursor-pointer">
                  {info.type === 'Link' && <FiEdit className="h-5 w-5 text-[#006BEA]" onClick={()=>handleEditUrl(info)}/>}
                  <AiOutlineDelete
                    className="h-5 w-5 text-[#FA0000]"
                    onClick={() => deleteDocuments(info.uuid)}
                  />
                </div>
                <div className="h-3/4 flex justify-center items-center text-[#006BEA]">
                  {info.type === 'Link' ? (
                    <RiLink className="w-10 h-10 " />
                  ) : (
                    <FaRegFileAlt className="w-10 h-10 " />
                  )}
                </div>
              </div>
              <div className="flex justify-start">
              <p className="text-[24px] font-normal leading-[28.13px] text-[#ffffff] truncate w-full text-start">
              {info.name}
            </p>
              </div>
            </div>
          ))}
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};
export default FaqFillScreen;
