import { API_URL } from './Environment';

export interface IClientRegister {
  businessName: string;
  email: string;
  password: string;
}

export interface IClientLogin {
  email: string;
  password: string;
}

export interface IForgotPassword {
  email: string;
}

export interface IResetPassword {
  password: string;
}
async function loginUser(userdetails: IClientLogin) {
  // api for user login
  const response = await fetch(API_URL + 'sessions/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}

async function signUpUser(userdetails: IClientRegister) {
  // api for user signup
  const response = await fetch(API_URL + 'sessions/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}

async function forgotPassword(userdetails: IForgotPassword) {
  // api for forgot password
  const response = await fetch(API_URL + 'sessions/forgot-password', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}

async function resetPassword(token: string, userdetails: IResetPassword) {
  // API for resetting password
  const response = await fetch(`${API_URL}sessions/reset-password/${token}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}

//API for verify email

const verifyUser = async (userUuid: string) => {
  try {
    const response = await fetch(`${API_URL}/sessions/${userUuid}/verify`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' }
    });

    if (!response.ok) {
      throw new Error('Verification failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error verifying user:', error);
    throw new Error('An error occurred during email verification. Please try again.');
  }
};

export { loginUser, signUpUser, forgotPassword, resetPassword, verifyUser };
