import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AccountSetupWizard from '../components/client-setup/AccountSetupWizard';
import LandingScreen from '../components/Authentication/LandingScreen';
import AccountSetupNavigation from './AccountSetupNavigation';
import SidebarMenuNavigation from './SidebarMenuNavigation';
import AuthNavigation from './AuthNavigation';
import SettingsNavigation from './SettingsNavigation';
import DashboardLayout from '../components/dashboard/DashboardLayout';

const Navigation = () => {

  const listOfNavigation = [
    // {
    //   path: '/',
    //   element: <LandingScreen />
    // },
    {
      path: '/account-setup/*',
      element: (
        <AccountSetupWizard>
          <AccountSetupNavigation />
        </AccountSetupWizard>
      )
    },
    {
      path: '/settings/*',
      element: (
        <DashboardLayout>
          <SettingsNavigation />
        </DashboardLayout>
      )
    }
  ];

  const MainNavigation = () => {
    return (
      <Routes>
        {listOfNavigation.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    );
  };
  return (
    <BrowserRouter>
     {sessionStorage.getItem('userUUID') &&  <MainNavigation />}
      
     {sessionStorage.getItem('userUUID') && <SidebarMenuNavigation />} 
       <AuthNavigation />
        
    </BrowserRouter>
  );
};

export default Navigation;
