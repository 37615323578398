import React, { ReactNode, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { IoSettingsOutline } from 'react-icons/io5';
import { IoSearchSharp } from 'react-icons/io5';

import complaintsImage from '../../assets/Complaints.png';
import vendorsBlueImage from '../../assets/Vendors-blue.png';
import vendorswhiteImage from '../../assets/Vendors-white.png';
import dashboardBlueImage from '../../assets/Dashboard-blue.png';
import dashboardwhiteImage from '../../assets/Dashboard-white.png';
import blueCompaintsImage from '../../assets/Compaints-blue.png';
import SupportIcon from '../../assets/support.png';
import HelpCenter from '../../assets/HelpCenter.png';
import BellIcon from '../../assets/Bell.png';
import Sidebar from '../../common/layout/Sidebar';
import DashboardSidebarItem from '../../common/SidebarItem';
import Navbar from '../../common/layout/Navbar';
import { notifyDashboard } from '../../api/Notification';
interface dashboardLayoutProps {
  children: ReactNode;
}
const DashboardLayout = (props: dashboardLayoutProps) => {
  const location = useLocation();
  const { children } = props;

  const [activeSidebar, setActiveSidebar] = useState(1);
  const [hasNotifications, setHasNotifications] = useState(false);
  const sidebarItems = [
    {
      id: 1,
      image: '/dashboard' === location.pathname ? dashboardBlueImage : dashboardwhiteImage,
      label: 'Dashboard',
      menuUrl: '/dashboard'
    },
    {
      id: 2,
      image: '/complaints' === location.pathname ? blueCompaintsImage : complaintsImage,
      label: 'Complaints',
      menuUrl: '/complaints'
    },
    {
      id: 3,
      image: location.pathname.startsWith('/vendors') ? vendorsBlueImage : vendorswhiteImage,
      label: 'Vendors',
      menuUrl: '/vendors'
    }
  ];

  const handleSidebarItemsClick = (sidebarItemId: number) => {
    setActiveSidebar(sidebarItemId);
  };
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await notifyDashboard();
        const data = await response.json();

        if (Array.isArray(data?.payload?.notification) && data.payload.notification.length > 0) {
          setHasNotifications(true);
        } else {
          setHasNotifications(false);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setHasNotifications(false);
      }
    };

    fetchNotifications();
  }, []);
  const inputHeader = (
    <div className="">
      {/* flex items-center bg-[#1E1E1E] text-white rounded-3xl ms-8 px-4 py-2 w-[200px] sm:w-[364px] md:h-[48px] h-[30px] border-[2px] border-[#FFFFFF */}
      {/* <input
        type="text"
        placeholder="Search here"
        className="bg-transparent outline-none  flex-grow placeholder-[#FFFFFF] md:placeholder:text-[18px] placeholder:text-[6px] md:text-[18px] text-[6px]"
      />

      <button>
        <IoSearchSharp className="sm:h-[20px] sm:w-[20px] h-2 w-2  text-[#FFFFFF]" />
      </button> */}
    </div>
  );

  const bellAndSettingsIcon = (
    <div className="flex space-x-4 text-white">
      <div className="relative mx-2 lg:mx-0">
        <Link to="/notifications">
          {/* <img src={BellIcon} className="lg:w-[23px] lg:h-[25px]  w-[10px] h-[10px] " alt="bell" /> */}
          <img src={BellIcon} className="md:w-[23px] md:h-[25px] w-[15px] h-[18px]" alt="bell" />
        </Link>

        {/* Render red indicator conditionally */}
        {hasNotifications && (
          // <span className="absolute top-0 right-0 inline-block sm:w-2.5 sm:h-2.5 w-1 h-1 bg-red-500 rounded-full"></span>
          <span className="absolute top-0 right-0 inline-block md:w-2.5 md:h-2.5 w-2 h-2 bg-red-500 rounded-full"></span>
        )}
      </div>

      <Link to="/settings/faq">
        <IoSettingsOutline
          // className={`w-[25px] h-[25px]  w-[10px] h-[10px] ${location.pathname.startsWith('/settings') ? 'text-[#006BEA]' : 'text-white'}`}
          className={`md:w-[25px] md:h-[25px] w-[20px] h-[20px]  ${location.pathname.startsWith('/settings') ? 'text-[#006BEA]' : 'text-white'}`}
        />
      </Link>
    </div>
  );

  const supportAndHelpdeskContent = (
    <div className="space-y-4 ms-6 ">
      {/* <div className={`flex items-center gap-4 p-2 rounded-md transition`}>
        <img className={`text-lg lg:text-xl`} src={HelpCenter} alt="help" />
        <span className="text-sm lg:text-[16px] hidden lg:block">
          <Link to={'/help-center'}>Help Center</Link>
        </span>
      </div> */}
      <div className={`flex items-center gap-4 p-2 rounded-md  transition`}>
      <img className={`text-lg lg:text-xl`} src={HelpCenter} alt="help" />

        <span className="text-sm lg:text-[16px] hidden lg:block">
          <Link to={'/support'}>Support</Link>
        </span>
      </div>
    </div>
  );
  return (
    <div className="flex h-screen bg-[1E1E1E] text-white overflow-hidden">
      <Sidebar supportAndHelpdeskContent={supportAndHelpdeskContent}>
        <div className="flex flex-col justify-between ms-2">
          <div>
            {/* <h3 className="text-[#FFFFFF] text-xs lg:text-[16px] font-normal mb-4 lg:mb-6">
              MAIN MENU
            </h3> */}

            <div className="space-y-4">
              {sidebarItems.map((item) => (
                <DashboardSidebarItem
                  key={item.id}
                  image={item.image}
                  label={item.label}
                  isActive={activeSidebar === item.id}
                  handleSidebarItemsClick={handleSidebarItemsClick}
                  id={item.id}
                  url={item.menuUrl}
                />
              ))}
            </div>
          </div>
        </div>
      </Sidebar>

      <Navbar header={inputHeader} bellAndSettingsIcon={bellAndSettingsIcon} layoutPadding={true}>
        {children}
      </Navbar>
    </div>
  );
};

export default DashboardLayout;
