import moment from 'moment';

const startOfYear = moment().startOf('year').format('DD-MM-YYYY');
const endOfYear = moment().endOf('year').format('DD-MM-YYYY');

const startOfWeek = moment().startOf('week').format('DD-MM-YYYY');
const endOfWeek = moment().endOf('week').format('DD-MM-YYYY');

const startOfMonth = moment().startOf('month').format('DD-MM-YYYY');
const endOfMonth = moment().endOf('month').format('DD-MM-YYYY');

const previousStartYear = moment().subtract(1, 'year').startOf('year').format('DD-MM-YYYY');
const previousEndYear = moment().subtract(1, 'year').endOf('year').format('DD-MM-YYYY');

const previousTwoStartYear = moment().subtract(2, 'year').startOf('year').format('DD-MM-YYYY');
const previousTwoEndYear = moment().subtract(2, 'year').endOf('year').format('DD-MM-YYYY');

export default {
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfMonth,
  endOfWeek,
  endOfYear,
  previousStartYear,
  previousEndYear,
  previousTwoStartYear,
  previousTwoEndYear
};
