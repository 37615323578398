// src/utils/errorMessages.ts
export const ErrorMessages = {
  businessName: {
    required: 'Business name is required',
    invalid: 'Enter valid Business name'
  },
  email: {
    required: 'Email is required',
    invalid: 'Valid email is required'
  },
  password: {
    required: 'Password is required',
    invalid: 'Password is invalid'
  },
  confirmPassword: {
    required: 'Confirm password is required',
    mismatch: 'Passwords do not match'
  },
  targetWebsite: {
    required: 'Website link is required',
    invalid: 'Invalid website link (e.g., "www.xyz.com")'
  },
  companyName: {
    required: 'Company name is required'
  },
  contactNumber: {
    required: 'Contact number is required',
    invalid: 'Contact number must be exactly 10 digits'
  },
  address: {
    required: 'Address is required'
  },
  industry: {
    required: 'Industry name is required.'
  },
  name: {
    required: 'Name is required'
  },
  employeeId: {
    required: 'Employee ID is required'
  },
  position: {
    required: 'Position is required'
  },
  question: {
    required: 'Question is required'
  },
  answer: {
    required: 'Answer is required'
  }
};
