import { API_URL } from './Environment';

async function notifyDashboard() {
  const response = await fetch(API_URL + `dashboard/notification`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch vendors: ${response.statusText}`);
  }

  return response;
}

async function updateNotification(notificationUuid: string) {
  const response = await fetch(
    API_URL + `dashboard/mark-as-read?notificationUuid=${notificationUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to put Notification: ${response.statusText}`);
  }

  return response;
}

export { notifyDashboard, updateNotification };
