import { useNavigate } from 'react-router-dom';
import ComplaintsCard from './ComplaintsCard';

const ComplaintsRaising = ({ complaintRaise }: any) => {
  const navigate = useNavigate();
  const navigatetocomplaints = () => {
    navigate('/complaints');
  };
  return (
    // <div className="flex flex-col gap-y-4 bg-[#343434] rounded-lg p-4 pr-2">
    <div className="flex flex-col bg-[#343434] rounded-lg p-4 pr-2">
      <label className="text-lg font-normal text-white">Complaints Raising</label>

      {complaintRaise?.raisedComplaints?.length === 0 ? (
        <div className="h-[316px] text-center content-center">
          <label className="text-gray-300">No Complaint Raised</label>
        </div>
      ) : (
        <div className="flex flex-col gap-y-1.5 h-[316px] overflow-y-auto pr-2">
          <ComplaintsCard info={complaintRaise} navigatetocomplaints={navigatetocomplaints} />
        </div>
      )}
    </div>
  );
};

export default ComplaintsRaising;
