import React from 'react';
import { FaAngleLeft } from 'react-icons/fa6';

interface BackButtonProps {
  onClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <div
      className="bg-[#006BEA] mt-3 rounded w-[27px] h-[27px] ml-0 mr-1 cursor-pointer flex items-center justify-center"
      onClick={onClick}
    >
      <FaAngleLeft className="w-[20px] h-[20px]" />
    </div>
  );
};
