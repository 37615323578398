import React, { useEffect, useState, useRef, Fragment } from 'react';
import { IoMdClose } from 'react-icons/io';
import CommonPopup from '../../common/CommonPopup';
import { getVendorList, vendorAssigned } from '../../api/Complaints';
import ToastNotification from '../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import Loader from '../../common/Loader';

interface Vendor {
  uuid: string;
  name: string;
  complaints: number;
  complaintData: any[];
}
interface IProps {
  tickets: any;
  currentPage: number;
  selectedComplaintsInfo: any;
  handleFetchTickets: any;
  onClose: () => void;
}

const AssignVendorPopup = (props: IProps) => {
  const { tickets, selectedComplaintsInfo, onClose, handleFetchTickets, currentPage } = props;

  const companyUuid = sessionStorage.getItem('companyUuid') || '';
  const observer = useRef<IntersectionObserver | null>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [showLoading, setShowLoading] = useState(true);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchVendors = async (currentPage: number) => {
    setShowLoading(true);
    try {
      const response = await getVendorList(companyUuid ? companyUuid : '', currentPage, 10);
      const formattedVendors = response.payload.vendor.map((vendor: any) => ({
        uuid: vendor.vendorUuid,
        name: vendor.name,
        complaints: vendor.complaint,
        complaintData: vendor.complaintData
      }));
      setVendorList(formattedVendors);
      setHasMore(formattedVendors.length > 0);
      setShowLoading(false);
    } catch (err: any) {
      setShowLoading(false);
    }
  };

  const handleAssign = async (vendorUuid: string) => {
    try {
      setLoading(true);
      const getSelectedIndex = tickets.findIndex(
        (info: any) => info.uuid === selectedComplaintsInfo.uuid
      );
      const complaintUuid = selectedComplaintsInfo.uuid;

      const response = await vendorAssigned({
        vendorUuid,
        companyUuid,
        complaintUuid
      });
      if (response.ok) {
        const data = await response.json();
        ToastNotification({
          message: data.message
        });
        handleFetchTickets('All', currentPage, '', '', getSelectedIndex);
        fetchVendors(page);
      } else {
        const errorData = await response.json();
        ToastNotification({
          message: errorData.message,
          type: 'warning'
        });
      }
    } catch (err: any) {}
  };

  useEffect(() => {
    if (loading || !hasMore) return;

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleObserver);
    if (loaderRef.current) observer.current.observe(loaderRef.current);

    return () => observer.current?.disconnect();
  }, [loading, hasMore]);

  useEffect(() => {
    fetchVendors(page);
  }, [page]);

  return (
    <CommonPopup onClose={onClose} width="w-1/3">
      <div>
        <div className="flex justify-end cursor-pointer me-3" onClick={onClose}>
          <IoMdClose size={24} />
        </div>

        <div className="flex flex-col gap-4 h-[350px] overflow-y-auto mx-1.5 pt-4">
          {showLoading ? (
            <div className="flex justify-center items-center absolute inset-0">
              <Loader />
            </div>
          ) : (
            <Fragment>
              {vendorList.length > 0 ? (
                vendorList.map((info: Vendor, index: number) => (
                  <div
                    className="flex justify-between items-center gap-4 text-base font-normal text-white"
                    key={index}
                  >
                    <div className="">
                      <div className="w-11 h-11 bg-[#D9D9D9] rounded-full cursor-pointer items-center flex justify-center text-black font-regular text-lg">
                        {info.name[0]?.toUpperCase() || 'A'}
                      </div>
                    </div>

                    <div className="justify-center w-28 text-left">
                      <label>{info.name}</label>
                    </div>

                    <div className="justify-center">
                      <label>{info.complaints} complaint</label>
                    </div>

                    <div className="flex me-2">
                      <button
                        disabled={info?.complaintData?.find(
                          (complaintUuid: any) => complaintUuid.uuid === selectedComplaintsInfo.uuid
                        )}
                        type="button"
                        className={`px-3.5 py-1.5 font-bold text-base rounded w-28 ${
                          info?.complaintData?.find(
                            (complaintUuid: any) =>
                              complaintUuid.uuid === selectedComplaintsInfo.uuid
                          )
                            ? 'bg-[#FFFFFF] text-[#006BEA] cursor-not-allowed'
                            : 'bg-[#006BEA] text-white'
                        }`}
                        onClick={() => handleAssign(info.uuid)}
                      >
                        {info?.complaintData?.find(
                          (complaintUuid: any) => complaintUuid.uuid === selectedComplaintsInfo.uuid
                        )
                          ? 'Assigned'
                          : 'Assign'}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center h-3/6 w-full text-white text-lg font-bold">
                  No record found
                </div>
              )}
            </Fragment>
          )}

          <ToastContainer />
        </div>

        <div ref={loaderRef} className="h-1"></div>
      </div>
    </CommonPopup>
  );
};

export default AssignVendorPopup;
