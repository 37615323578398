import { LuTrash } from 'react-icons/lu';

interface IProps {
  info: any;
  index: number;
  selectedTicketIdx: any;
  setSelectedTicketIdx: any;
  markAsRead: any;
}

const NotificationCard = (props: IProps) => {
  const { info, index, selectedTicketIdx, setSelectedTicketIdx, markAsRead } = props;

  return (
    <div
      className={`bg-[none] rounded-lg pt-3.5 pl-3.5 pr-3 pb-1.5 cursor-pointer border-[#696969] border-2 ${
        selectedTicketIdx === index ? 'border-blue-500' : '#696969'
      }`}
      key={index}
      onClick={() => setSelectedTicketIdx(index)}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-x-5 items-center">
          <label className="text-lg text-white font-regular cursor-pointer">{info.message}</label>
        </div>
        <LuTrash
          onClick={(e) => {
            e.stopPropagation();
            markAsRead(info.uuid);
          }}
          color="#FF4C4C"
          className="w-[26px] h-[26px]"
        />
      </div>

      {/* <div className="flex gap-x-5">
        <label className="text-lg text-white font-regular line-clamp-2 mt-1 cursor-pointer">
          {info.message}
        </label>
      </div> */}

      <div className="flex justify-end mt-1">
        <label className="text-xs font-bold text-[#D7D7D7] text-right cursor-pointer">
          {info.createdTime}
        </label>
      </div>
    </div>
  );
};

export default NotificationCard;
