import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface ComplaintsAreaChartProps {
  data: { name: string; count: number }[];
}

const ComplaintsAreaChart = ({ data }: ComplaintsAreaChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={730}
        height={250}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorcount" x1="0" y1="0" x2="0" y2="1">
            <stop offset="65%" stopColor="#006BEA" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#343434" stopOpacity={0.5} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          contentStyle={{
            backgroundColor: '#343434',
            borderRadius: '5px',
            padding: '10px'
          }}
        />
        <Area
          type="monotone"
          dataKey="count"
          stroke="#F1F1F1"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorcount)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ComplaintsAreaChart;
