import React, { memo } from 'react';
import { AiOutlineQuestionCircle, AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

const QuestionItem = (props: any) => {
  const { item, index, editingIndex, handleEditQuestion, handleDeleteQuestion, questionsList } =
    props;
  const updateAnswerWithImageSize = (answer: string) => {
    const imgRegex = /<img[^>]+>/g;

    return answer?.replace(imgRegex, (imgTag) => {
      if (imgTag.includes('<img')) {
        return imgTag.replace(
          '<img',
          '<img style="height: 70px; width: 70px; margin: 10px ; margin-right:20px; display:inline-block;"'
        );
      }
      return imgTag;
    });
  };
  return (
    <div key={index}>
      <div className="md:flex items-center h-auto flex-wrap">
        <span className="mr-3">
          <AiOutlineQuestionCircle className="h-[28px] w-[28px] text-[#006BEA]" />
        </span>

        <p className="md:font-bold font-normal text-base md:text-[24px] text-[#ffffff] md:leading-[27.36px] flex-1">
          {item.question}
        </p>

        <span className="flex xe md:mt-0 mt-2">
          {!editingIndex && (
            <span className="md:ml-3 cursor-pointer" onClick={() => handleEditQuestion(index)}>
              <FiEdit className="h-6 w-6 text-[#006BEA]" />
            </span>
          )}
          <span
            className="ml-3 cursor-pointer"
            onClick={() => handleDeleteQuestion(index, item.uuid)}
          >
            <AiOutlineDelete className="h-7 w-7 text-[#FA0000]" />
          </span>
        </span>
      </div>

      <div className={`md:ml-12 mt-3 ${questionsList.length === index + 1 ? 'mb-24' : null}`}>
        <p
          className="md:font-normal font-light text-sm md:text-[22px]  leading-[25.08px] text-[#ffffff] "
          dangerouslySetInnerHTML={{ __html: updateAnswerWithImageSize(item.answer) }}
        ></p>
      </div>
    </div>
  );
};

export default memo(QuestionItem);
