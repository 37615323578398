import { useEffect, useState } from 'react';
import ProgressBarLogic from '../../common/ProgressBarLogic';
import InputBox from '../../common/InputBox';
import Button from '../../common/Button';
import ToastNotification from '../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import MatrixAiLogo from '../../common/MatrixAiLogo';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../api/Auth';
import EyeButton from '../../common/EyeButton';

interface FormData {
  password: string;
  confirmPassword: string;
}

interface ValidationErrors {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    password: '',
    confirmPassword: ''
  });
  const { token } = useParams<{ token?: string }>();
  const [errors, setErrors] = useState<ValidationErrors>({
    password: '',
    confirmPassword: ''
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [password, setPassword] = useState({ password: '' });
  const [score, setScore] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let isValid = true;
    const newErrors: ValidationErrors = {
      password: '',
      confirmPassword: ''
    };

    ['password', 'confirmPassword'].forEach((field) => {
      const value = formData[field as keyof FormData];
      let error = '';

      if (field === 'password') {
        if (!value.trim()) {
          ToastNotification({
            message: 'Password is required',
            type: 'error'
          });
        } else if (score < 90) {
          ToastNotification({
            message: 'Password is not strong enough. Please create a stronger password.',
            type: 'error'
          });
        }
      } else if (field === 'confirmPassword') {
        if (!value.trim()) {
          ToastNotification({
            message: 'ConfirmPassword is required',
            type: 'error'
          });
        } else if (value !== formData.password) {
          error = 'Passwords do not match';
          ToastNotification({
            message: 'Passwords do not match',
            type: 'error'
          });
        }
      }

      newErrors[field as keyof ValidationErrors] = error;
      if (error) {
        isValid = false;
        setFormData({
          password: '',
          confirmPassword: ''
        });
      }
    });

    setErrors(newErrors);

    if (isValid) {
      try {
        // Call the resetPassword API
        const response = await resetPassword(token ?? '', {
          password: formData.password || ''
        });
        //Check if the API response is successful
        if (response.ok) {
          const data = await response.json();
          setScore(0);
          ToastNotification({
            message: data.message
          });

          setTimeout(() => {
            navigate('/login');
            setFormData({
              password: '',
              confirmPassword: ''
            });
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
        }
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    const validateForm = () => {
      const isValid = !!formData.password && !!formData.confirmPassword && score >= 90;
      setIsFormValid(isValid);
    };

    validateForm();
  }, [formData, score]);

  return (
    <>
      <div className="min-h-full flex flex-col justify-center px-2 py-3 lg:px-8 ">
        <MatrixAiLogo />
        <div className="sm:mx-auto sm:w-full sm:max-w-lg md:mt-20">
          <h2 className="mt-10 text-start text-[48px] font-bold tracking-tight md:leading-[56.25px]">
            Reset Password
          </h2>
        </div>

        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-lg">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <div className="mt-2 relative">
                <InputBox
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  placeholder="Enter new password"
                  handleChange={(e: any) => {
                    handleChange(e);
                    setPassword(e.target.value);
                  }}
                  error=""
                />
                <EyeButton
                  showPassword={showPassword}
                  togglePasswordVisibility={togglePasswordVisibility}
                />
              </div>

              <div className="h-[12px] block mt-2">
                {formData.password.length > 0 && (
                  <ProgressBarLogic password={password} setScore={setScore} score={score} />
                )}
              </div>
            </div>

            <div>
              <div className="mt-2 relative">
                <InputBox
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formData.confirmPassword}
                  placeholder="Confirm new password"
                  handleChange={handleChange}
                  error=""
                />
                <EyeButton
                  showPassword={showConfirmPassword}
                  togglePasswordVisibility={toggleConfirmPasswordVisibility}
                />
              </div>
            </div>

            <div className="flex place-content-center">
              <Button
                isFormValid={isFormValid}
                btnText="Reset"
                textColor="#FFFFFF"
                backGroundColor="#006BEA"
                borderColor=""
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
