import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputBox from '../../../../common/InputBox';
import Button from '../../../../common/Button';
import { phoneNumberRangeRegex } from '../../../../function/Regex';
import { validateForm } from '../../../../function/FormValidation';
import { getCompanyDetails, updateCompany } from '../../../../api/AccountSetup';
import ToastNotification from '../../../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';

const CreateCompanyProfile = () => {
  const navigate = useNavigate();
  const uuid = sessionStorage.getItem('companyUuid');

  const [formData, setFormData] = useState({
    companyName: '',
    contactNumber: '',
    address: '',
    industry: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyData = await getCompanyDetails(uuid ? uuid : '');
        const companyDetails = companyData?.payload?.companyDetail;
        if (companyDetails) {
          setFormData({
            companyName: companyDetails?.companyName || '',
            contactNumber: companyDetails?.phone || '',
            address: companyDetails?.address || '',
            industry: companyDetails?.industry || ''
          });
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
        setErrors((prev) => ({ ...prev, fetch: 'Failed to fetch company details.' }));
      }
    };

    fetchCompanyDetails();
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleCreateCompanyProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData, [
      'companyName',
      'contactNumber',
      'address',
      'industry'
    ]);
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      try {
        const response = await updateCompany(
          {
            companyName: formData.companyName,
            contactNumber: formData.contactNumber,
            address: formData.address,
            industry: formData.industry
          },
          uuid ? uuid : ''
        );
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });
          setTimeout(() => {
            navigate('/account-setup/faq');
            setDisable(false);
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
          setDisable(false);
        }
      } catch (error) {
        setDisable(false);
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 bg-[#1E1E1E] rounded-lg  lg:px-8 pt-6 p-2 overflow-y-auto">
      <h2 className="lg:text-[22px] text-xs font-normal mb-[5px] lg:h-[31px] ms-1">STEP 1</h2>
      <h3 className="lg:text-[28px] text-sm mb-[32px] font-normal lg:h-[39px] ms-1">
        Create company Profile
      </h3>
      <form
        className="flex flex-col justify-between  flex-1 ms-1"
        onSubmit={handleCreateCompanyProfile}
        method="Post"
      >
        <div className="grid lg:grid-cols-2 grid-cols-1  gap-x-[60px] gap-y-[20px]">
          <div>
            <InputBox
              name="companyName"
              type="text"
              value={formData.companyName}
              placeholder="Company Name"
              handleChange={handleChange}
              error={errors.companyName}
            />
          </div>
          <div>
            <InputBox
              name="industry"
              type="text"
              value={formData.industry}
              placeholder="Industry"
              handleChange={handleChange}
              error={errors.industry}
            />
          </div>
          <div>
            <InputBox
              name="address"
              type="text"
              value={formData.address}
              placeholder="Address"
              handleChange={handleChange}
              error={errors.address}
            />
          </div>
          <div>
            <InputBox
              name="contactNumber"
              type="text"
              value={formData.contactNumber}
              placeholder="Contact Number"
              error={errors.contactNumber}
              handleChange={(e) => {
                const value = e.target.value;
                if (phoneNumberRangeRegex.test(value)) {
                  handleChange(e);
                }
              }}
            />
          </div>
        </div>
        <div className="flex justify-end mt-auto lg:mb-2">
          <Button
            isFormValid={true}
            disable={disable}
            btnText="Next"
            textColor="#FFFFFF"
            backGroundColor="#006BEA"
          />
        </div>
      </form>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default CreateCompanyProfile;
