import React from 'react';
import { TbCheck } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
interface step {
  id: number;
  step: string;
  stepUrl: string;
  dbName: string;
}
interface submittedStep {
  companyDetail: string | null;
  faq: string | null;
  widget: string | null;
  integration: string | null;
}

interface stepperProps {
  steps: step[];
  currentStep: number;
  submittedStep: submittedStep;
  handleStepClick: (id: number) => void;
}

const Stepper = (props: stepperProps) => {
  const location = useLocation();
  const { steps, submittedStep, handleStepClick } = props;

  return (
    <div className="flex flex-col lg:ms-6   ms-3">
      {steps.map((step, index) => (
        <div key={index} className="flex ">
          <div className="flex flex-col items-center">
            <Link
              to={
                submittedStep[step.dbName as keyof submittedStep] === 'completed'
                  ? step.stepUrl
                  : `${window.location.pathname}`
              }
            >
              <div
                className={`rounded-full lg:w-6 lg:h-6 w-4 h-4 border-2 cursor-pointer  ${
                  step.stepUrl === location.pathname ? ' p-[3px]' : 'p-0'
                }  mt-0 `}
                onClick={
                  submittedStep[step.dbName as keyof submittedStep] === 'completed'
                    ? () => handleStepClick(step.id)
                    : undefined
                }
              >
                <span
                  className={`flex items-center justify-center w-full h-full rounded-full  ${
                    step.stepUrl === location.pathname
                      ? 'bg-blue-500 text-white border-none'
                      : 'border-[#FFFFFF] text-white'
                  }`}
                >
                  {submittedStep[step.dbName as keyof submittedStep] === 'completed' &&
                  step.stepUrl !== location.pathname ? (
                    <TbCheck className="w-5 h-5" />
                  ) : (
                    <span className="block w-3 h-3 rounded-full bg-transparent border-none"></span>
                  )}
                </span>
              </div>
            </Link>
            {index !== steps.length - 1 && (
              <div className=" h-20  border-l-2 border-dashed border-[#ffffff]"></div>
            )}
          </div>

          <div className="ml-2 mt-[-5px] lg:mt-0 pt-0">
            <span className={`lg:text-[18px] text-[12px] text-white font-normal `}>
              <Link
                to={
                  submittedStep[step.dbName as keyof submittedStep] === 'completed'
                    ? step.stepUrl
                    : `${window.location.pathname}`
                }
              >
                {step.step}
              </Link>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
