import { API_URL } from './Environment';

interface companyInfo {
  companyName: string;
  industry: string;
  address: string;
  contactNumber: string;
}

interface companyDetail {
  companyName: string;
  industry: string;
  address: string;
  phone: string;
}
interface apiResponseComapnyInfo {
  payload: {
    companyDetail: companyDetail;
  };
}
interface faqInfo {
  document: { uuid: string | null; faq: { question: string; answer: string }[] }[];
}

interface updateFaqInfo {
  document: { question: string; answer: string; uuid?: string | null }[];
}
interface editFaqUrlData {
  uuid: string;
  url: string;
}

interface FaqListResponse {
  payload: {
    list: {
      question: string;
      answer: string;
    }[];
    total: number;
    page: number;
    limit: number;
  };
  message: string;
}

interface documentListResp {
  payload: {
    result: {
      uuid: string;
      type: string;
      name: string;
    }[];
  };
}

interface urlList {
  urls: string[];
}

interface widgetInfo {
  theme: string;
  name: string;
  website: string;
}

interface updateWidgetInfo {
  uuid: string;
  theme: string;
  name: string;
  website: string;
}

async function updateCompany(companyDetails: companyInfo, uuid: string) {
  const response = await fetch(API_URL + `admin/update-company-detail?companyUuid=${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(companyDetails)
  });
  return response;
}
async function getCompanyDetails(uuid: string) {
  const response = await fetch(API_URL + `admin/company-detail?companyUuid=${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  const data: apiResponseComapnyInfo = await response.json();
  return data;
}

async function getStepperCheck(uuid: string) {
  const response = await fetch(API_URL + `admin/account-detail?companyUuid=${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  const data = await response.json();
  return data;
}

async function createFaq(faqDetails: faqInfo, companyUuid: string) {
  const response = await fetch(API_URL + `admin/create-faq?companyUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(faqDetails)
  });
  return response;
}

async function updateFaq(faqDetails: updateFaqInfo, companyUuid: string) {
  const response = await fetch(API_URL + `admin/update-faq?companyUuid=${companyUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(faqDetails)
  });
  return response;
}

async function deleteFaq(faqUuid: string, companyUuid: string) {
  const response = await fetch(API_URL + `admin/delete-faq?companyUuid=${companyUuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify({ uuid: faqUuid })
  });
  return response;
}

async function getFaqList(companyUuid: string) {
  const response = await fetch(`${API_URL}admin/faq-list?companyUuid=${companyUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });

  if (!response.ok) {
    throw new Error(`Error fetching FAQ list: ${response.statusText}`);
  }

  const faqList: FaqListResponse = await response.json();
  return faqList;
}

async function uploadFaqDocuments(files: File[], companyUuid: string) {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`document`, file);
  });

  const response = await fetch(`${API_URL}admin/upload-faq-documents?companyUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: formData
  });

  return response.json();
}

async function uploadFaqURLs(URLs: urlList, companyUuid: string) {
  const response = await fetch(`${API_URL}admin/upload-faq-url?companyUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(URLs)
  });

  return response.json();
}

async function editFaqUrl(editFaqUrlData: editFaqUrlData, companyUuid: string) {
  console.log(editFaqUrlData, 'editFaqUrlData');
  const response = await fetch(API_URL + `admin/edit-faq-url?companyUuid=${companyUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(editFaqUrlData)
  });
  return response.json();
}

async function deleteFaqDocument(docUuid: string, companyUuid: string) {
  const response = await fetch(API_URL + `admin/delete-doc?companyUuid=${companyUuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify({ uuid: docUuid })
  });
  return response;
}

async function getListOfDocuments(uuid: string) {
  const response = await fetch(API_URL + `admin/get-doc?companyUuid=${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });

  if (!response.ok) {
    throw new Error(`Error fetching FAQ list: ${response.statusText}`);
  }

  const documentList: documentListResp = await response.json();
  return documentList;
}

async function generateFAQFromDocuments(uuids: { uuids: string[] }, companyUuid: string) {
  const response = await fetch(`${API_URL}admin/generate-faq?companyUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(uuids)
  });

  return response.json();
}

async function generateWidgets(widgetInfo: widgetInfo, companyUuid: string) {
  const response = await fetch(`${API_URL}admin/create-widges?companyUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(widgetInfo)
  });

  return response.json();
}

async function getWidgetsInfo(companyUuid: string) {
  const response = await fetch(API_URL + `admin/get-widges?companyUuid=${companyUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });

  if (!response.ok) {
    throw new Error(`Error fetching widgetInfo: ${response.statusText}`);
  }

  const widgetInfo: any = await response.json();
  return widgetInfo;
}

async function updateWidgets(widgetInfo: updateWidgetInfo, companyUuid: string) {
  const response = await fetch(`${API_URL}admin/update-widges?companyUuid=${companyUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(widgetInfo)
  });

  return response.json();
}

async function getListOfIntegration(companyUuid: string) {
  const response = await fetch(`${API_URL}integration/list?companyUuid=${companyUuid}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  const list: any = await response.json();
  return list;
}

async function intiateOAuthForCRM(integrationPartyUuid: string, companyUuid: string) {
  const response = await fetch(
    `${API_URL}integration/crm/oauth-callback?integrationPartyUuid=${integrationPartyUuid}&companyUuid=${companyUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );
  const authInfo: any = await response.json();
  return authInfo;
}

async function verifyTokenInfo(integrationPartyUuid: string, companyUuid: string, code: string) {
  const response = await fetch(
    `${API_URL}integration/crm/token?integrationPartyUuid=${integrationPartyUuid}&companyUuid=${companyUuid}&code=${code}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );
  const tokenInfo: any = await response.json();
  return tokenInfo;
}

export {
  createFaq,
  updateFaq,
  deleteFaq,
  getFaqList,
  updateCompany,
  getCompanyDetails,
  getStepperCheck,
  uploadFaqDocuments,
  uploadFaqURLs,
  editFaqUrl,
  deleteFaqDocument,
  getListOfDocuments,
  generateFAQFromDocuments,
  generateWidgets,
  getWidgetsInfo,
  updateWidgets,
  getListOfIntegration,
  intiateOAuthForCRM,
  verifyTokenInfo
};
