import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BackButton } from '../../common/BackButton';
import { useNavigate, useLocation } from 'react-router-dom';
import RichTextBox from '../client-setup/pages/faq/faq-manual/RichTextBox';
import { deleteFaq, getFaqList } from '../../api/AccountSetup';
import ToastNotification from '../../common/ToastNotification';
import { toast } from 'react-toastify';

const SettingsSidebar = ({ children }: any) => {
  const companyUuid = sessionStorage.getItem('companyUuid');
  const navigate = useNavigate();
  const location = useLocation();
  const isFaqQuestionListComponent = location.pathname === '/settings/faq';
  const [isLoading, setIsLoading] = useState(false);

  const [questionsList, setQuestionsList] = useState<
    { question: string; answer: string; uuid?: string | null | undefined }[]
  >([]);
  const [isAddOrEditPopUpOpen, setIsAddOrEditPopUpOpen] = useState(false);

  const handleDeleteFaq = async (index: number, faqUUID: string) => {
    if (faqUUID) {
      setIsLoading(true);
      const resp = await deleteFaq(faqUUID, companyUuid ? companyUuid : '');
      if (resp.ok) {
        setIsLoading(false);
        ToastNotification({
          message: 'Deleted Successfully',
          type: 'success'
        });
        fetchAllFaq();
        // setTimeout(() => {
        // toast.dismiss();
        // }, 1500);
      } else {
        setIsLoading(false);

        ToastNotification({
          message: 'Something went wrong',
          type: 'error'
        });
      }
    } else {
      const updatedQuestions = questionsList.filter((_, i) => i !== index);
      setQuestionsList(updatedQuestions);
      ToastNotification({
        message: 'Removed',
        type: 'success'
      });
    }
  };

  const fetchAllFaq = async () => {
    try {
      setIsLoading(true);
      const faqList = await getFaqList(companyUuid ? companyUuid : '');
      setIsLoading(false);
      setQuestionsList(faqList.payload.list || []);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching FAQ list:', error);
      setQuestionsList([]);
    }
  };
  useEffect(() => {
    fetchAllFaq();
  }, []);
  const SettingsSidebarItems = [
    {
      id: 1,
      name: 'FAQ',
      urlLink: '/settings/faq'
    },
    {
      id: 2,
      name: 'Widgets',
      urlLink: '/settings/widgets'
    },
    {
      id: 3,
      name: 'Integration',
      urlLink: '/settings/integration'
    },
    {
      id: 4,
      name: 'Users',
      urlLink: '/settings/users'
    }
  ];
  return (
    <div className="flex h-screen bg-[#1E1E1E] text-white w-full">
      <div className="md:w-64 bg-[#1E1E1E]   py-10 ">
        <div className="flex gap-4 mb-[28px]">
          <BackButton onClick={() => navigate(-1)} />
          <h2 className="text-[28px] font-medium  mt-1"> Settings</h2>
        </div>
        <ul className="mt-4  border-r  border-[#595959] h-[80%]  md:w-[224px] w-[120px] md:ps-10">
          {SettingsSidebarItems.map((item, index) => (
            <li
              key={index}
              className={`md:w-[152px] h-[35px] text-left md:text-[20px] text-[18px] font-medium ${location.pathname === item.urlLink ? 'bg-[#FFFFFF] text-[#006BEA]' : ''} py-[6px] px-[10px] flex items-center rounded-[8px] mb-[18px]`}
            >
              <Link to={item.urlLink}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>
      {/* {isFaqQuestionListComponent&&<Loader/>} */}
      <div className={` mt-28 overflow-y-auto mb-[70px]   flex-col w-full flex-1 flex-grow`}>
        {!isFaqQuestionListComponent && children}
        {isFaqQuestionListComponent && (
          <RichTextBox
            questionsList={questionsList}
            setQuestionsList={setQuestionsList}
            isSettings={true}
            fetchAllFaq={fetchAllFaq}
            isAddOrEditPopUpOpen={isAddOrEditPopUpOpen}
            setIsAddOrEditPopUpOpen={setIsAddOrEditPopUpOpen}
            handleDeleteFaq={handleDeleteFaq}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
    </div>
  );
};
export default SettingsSidebar;
