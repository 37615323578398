import React from 'react';

interface navbarProps {
  children: React.ReactNode;
  header?: JSX.Element;
  bellAndSettingsIcon?: JSX.Element;
  isAccountSetup?: boolean;
  layoutPadding?: boolean;
}

const Navbar = (props: navbarProps) => {
  const { children, header, bellAndSettingsIcon, layoutPadding, isAccountSetup } = props;
  const companyName = sessionStorage.getItem('companyName');

  return (
    <div className="flex flex-col flex-1 ">
      <header className="flex justify-between items-center md:ps-2 md:pe-10 ps-2 pe-2 px-2 pt-4 sticky top-0 bg-[#1E1E1E] md:py-5">
        {header}

        <div className="flex items-center sm:space-x-6 space-x-6">
          {bellAndSettingsIcon}

          <div className="md:text-[22px] sm:text-[15px] text-[15px]">
            <span className="font-normal  block">Welcome!</span>
            <span className="font-bold block">{companyName ? companyName : ''}</span>
          </div>
        </div>
      </header>
      <main
        className={`flex flex-1 md:pb-2 md:px-5 px-2 ${isAccountSetup ? 'overflow-y-auto' : ''}  ${!layoutPadding && 'md:px-5 px-2'}`}
      >
        {children}
      </main>
    </div>
  );
};

export default Navbar;
