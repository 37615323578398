import React from 'react';
import MatrixAILogo from '../../assets/MatrixAI.png';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../Contexts/SocketContext';

interface sidebarProps {
  children: React.ReactNode;
  supportAndHelpdeskContent?: React.ReactNode;
}

const Sidebar = (props: sidebarProps) => {
  const { disconnectSocket } = useSocket();
  const navigate = useNavigate();
  const handleLogoutClick = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('companyName');
    sessionStorage.removeItem('companyUuid');
    sessionStorage.removeItem('userUUID');
    sessionStorage.removeItem('partyUuid');
    disconnectSocket();
    navigate('/');
  };
  const { children, supportAndHelpdeskContent } = props;

  return (
    <div className="lg:w-[320px] w-32 h-full min-h-[600px] bg-[#343434] lg:p-6 p-2 flex flex-col justify-between   sticky top-0 left-0">
      <div>
        <div className="lg:mb-14 mb-6 flex items-center p-2">
          <img src={MatrixAILogo} className="lg:w-[57px] w-6 mr-2" alt="Matrix AI" />
          <h2 className="lg:text-[40px] text-[13px] font-bold">Matrix AI</h2>
        </div>

        {children}
      </div>
      <div>
        {supportAndHelpdeskContent}

        <Button
          isFormValid={true}
          btnText="Logout"
          textColor="#006BEA"
          backGroundColor="white"
          onClick={handleLogoutClick}
        />
      </div>
    </div>
  );
};

export default Sidebar;
