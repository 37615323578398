import CommonPopup from '../../../../../common/CommonPopup';
import { RiDeleteBin5Line } from 'react-icons/ri';
import Button from '../../../../../common/Button';
import React, { useRef, useState } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import { FaRegFileAlt } from 'react-icons/fa';
import { uploadFaqDocuments } from '../../../../../api/AccountSetup';
import ToastNotification from '../../../../../common/ToastNotification';
import { toast, ToastContainer } from 'react-toastify';

const PopupFile = ({ onClose, fetchAllDocuments }: any) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const companyUuid = sessionStorage.getItem('companyUuid');

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };

  const handleRemoveFile = (fileName: string) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const getTruncatedFileName = (fileName: string) => {
    const extension = fileName.split('.').pop();
    const baseName = fileName.substring(0, fileName.length - extension!.length - 1);
    const truncatedBaseName = baseName.length > 6 ? baseName.substring(0, 6) : baseName;
    return `${truncatedBaseName}.${extension}`;
  };

  const handleSubmit = async () => {
    if (selectedFiles.length > 0) {
      const res = await uploadFaqDocuments(selectedFiles, companyUuid ? companyUuid : '');
      if (res.message === 'Successful') {
        ToastNotification({
          message: 'Documents Added',
          type: 'success'
        });
        fetchAllDocuments();
        setTimeout(() => {
          onClose();
          toast.dismiss();
        }, 1500);
      } else {
        ToastNotification({
          message: 'Something went wrong',
          type: 'error'
        });
      }
    } else {
      ToastNotification({
        message: 'Please select document',
        type: 'error'
      });
    }
  };

  const content = (
    <div className="flex flex-col gap-4">
      <div
        className="flex gap-4 flex-row-reverse items-start"
        style={{
          height: '150px',
          overflowY: 'auto'
        }}
      >
        <div
          className="grid gap-4 flex-grow"
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 0fr))',
            gridAutoRows: 'auto'
          }}
        >
          {selectedFiles.map((file) => (
            <div key={file.name} className="flex flex-col items-center">
              <div className="w-28 h-28 border-[3px] rounded-lg border-[#006BEA]">
                <div className="flex justify-end">
                  <RiDeleteBin5Line
                    height={13}
                    width={13}
                    className=" text-[#FA0000] hover:text-[#006BEA] cursor-pointer"
                    onClick={() => handleRemoveFile(file.name)}
                  />
                </div>
                <div className="h-3/4 flex justify-center items-center">
                  <FaRegFileAlt className="w-10 h-10 text-[#006BEA]" />
                </div>
              </div>
              <div className="mt-2">
                <p className="md:text-[24px] text-xl font-normal leading-[27.36px] text-[#ffffff] text-center">
                  {getTruncatedFileName(file.name)}
                </p>
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <div
              className="w-28 h-28 border-[3px] rounded-lg border-dashed border-[#006BEA] cursor-pointer"
              onClick={handleClick}
            >
              <div className="h-full flex  justify-center items-center">
                <MdOutlineFileUpload className="w-10 h-10 text-[#006BEA]" />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                onChange={handleFileChange}
                accept=".doc,.docx,.pdf"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );

  const footer = (
    <Button
      isFormValid={true}
      btnText="Submit"
      textColor="#FFFFFF"
      backGroundColor="#006BEA"
      borderColor="#006BEA"
      onClick={handleSubmit}
    />
  );

  return (
    <CommonPopup footer={footer} onClose={onClose}>
      {content}
    </CommonPopup>
  );
};

export default PopupFile;
