import { API_URL } from './Environment';

interface vendorInfo {
  fullName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  employeeID: string;
  dateOfBirth: string | null;
  position: string;
  address: string | null;
}

async function createVendor(vendorInfo: vendorInfo, companyUuid: string) {
  const response = await fetch(API_URL + `vendor/create-vendor?companyUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(vendorInfo)
  });
  return response;
}

async function getVendor(vendorid: string) {
  const response = await fetch(API_URL + `vendor/vendor-profile/${vendorid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  const data = await response.json();
  return data;
}

async function updateVendor(vendorDetail: vendorInfo, companyUuid: string, vendorUuid: string) {
  const response = await fetch(
    API_URL + `vendor/update-vendor?companyUuid=${companyUuid}&clientVendorUuid=${vendorUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(vendorDetail)
    }
  );
  return response;
}

async function vendorPagination(companyUuid: string, page: Number, limit: Number) {
  const response = await fetch(
    API_URL + `vendor?companyUuid=${companyUuid}&page=${page}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch vendors: ${response.statusText}`);
  }

  return response;
}

async function deleteVendor(companyUuid: string, clientVendorUuid: string) {
  const response = await fetch(
    API_URL +
      `vendor/delete-vendor?companyUuid=${companyUuid}&clientVendorUuid=${clientVendorUuid}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );

  return response;
}

export { createVendor, getVendor, updateVendor, vendorPagination, deleteVendor };
