import { FaPlus } from 'react-icons/fa';
import InputBox from '../../../../../common/InputBox';
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import JoditEditor from 'jodit-react';
import { validateForm } from '../../../../../function/FormValidation';
import { Config } from 'jodit/config';
import QuestionItem from './QuestionItem';
import CommonPopup from '../../../../../common/CommonPopup';
import faqAddORUpdate from './FaqAddOrUpdate';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../common/Button';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../../../common/Loader';
import { ImSpinner9 } from 'react-icons/im';

interface FormData {
  textBox: string;
  editors: string;
  uuid?: string | null;
}
interface Iprops {
  questionsList: Array<{ question: string; answer: string; uuid?: string | null }>;
  setQuestionsList: React.Dispatch<
    React.SetStateAction<Array<{ question: string; answer: string; uuid?: string | null }>>
  >;
  isSettings?: boolean;
  fetchAllFaq?: any;
  isAddOrEditPopUpOpen?: boolean;
  setIsAddOrEditPopUpOpen?: (open: boolean) => void;
  handleDeleteFaq: (index: number, faqUUID: string) => void;
  isLoading?: boolean;
  setIsLoading?:any;
}

const RichTextBox = (props: Iprops) => {
  const navigate = useNavigate();
  const {
    questionsList,
    setQuestionsList,
    isSettings,
    fetchAllFaq,
    setIsAddOrEditPopUpOpen,
    isAddOrEditPopUpOpen,
    handleDeleteFaq,
    isLoading,
    setIsLoading
  } = props;

  console.log('isAddOrEditPopUpOpen', isAddOrEditPopUpOpen);
  const [formData, setFormData] = useState<FormData>({
    textBox: '',
    editors: '',
    uuid: ''
  });
  const [isDisable, setDisable] = useState(false);
  const [errors, setErrors] = useState<{ question?: string; answer?: string }>({});
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!isAddOrEditPopUpOpen) {
      setFormData({ textBox: '', editors: '', uuid: null });
      setErrors({ answer: '', question: '' });
    }
  }, [isAddOrEditPopUpOpen]);

  const handleAddQuestion = () => {
    const { isValid, errors } = validateForm(
      { question: formData.textBox, answer: formData.editors },
      ['question', 'answer']
    );

    if (!isValid) {
      setErrors(errors);
      return;
    }

    setErrors({});

    if (editingIndex !== null) {
      const updatedQuestions = [...questionsList];
      updatedQuestions[editingIndex] = {
        question: formData.textBox,
        answer: formData.editors,
        uuid: formData.uuid
      };
      setQuestionsList(updatedQuestions);
    } else {
      setQuestionsList([
        ...questionsList,
        { question: formData.textBox, answer: formData.editors, uuid: null }
      ]);
    }
    if (setIsAddOrEditPopUpOpen) {
      setIsAddOrEditPopUpOpen(false);
    }
    setFormData({ textBox: '', editors: '', uuid: null });
    setEditingIndex(null);
  };

  const handleEditQuestion = (index: number) => {
    const questionToEdit = questionsList[index];
    setFormData({
      textBox: questionToEdit.question,
      editors: questionToEdit.answer,
      uuid: questionToEdit.uuid
    });
    setEditingIndex(index);
  };

  const handleEditorChange = (newContent: string) => {
    let cleanedContent = newContent;

    if (newContent === '<p><br></p>') {
      cleanedContent = '';
    }
    setFormData({ ...formData, editors: cleanedContent });
    if (errors.answer) {
      setErrors({ ...errors, answer: '' });
    }
  };

  function settingsFaqAddOrUpdate() {
    // setIsLoading(true);
    handleAddQuestion();
    setQuestionsList((prevQuestionsList) => {
      const updatedList = [...prevQuestionsList];
      setIsLoading(true);
      faqAddORUpdate({
        questionsList: updatedList,
        setQuestionsList,
        faqList: [],
        navigate,
        setDisable,
        isSettings,
        fetchAllFaq
      });
      return updatedList;
    });
    setIsLoading(false);
  }

  const config = useMemo(
    () =>
      ({
        readonly: false,
        showPoweredBy: false,
        uploader: {
          insertImageAsBase64URI: true
        },
        theme: 'dark',
        style: {
          color: 'white ',
          borderRadius: '10px'
        },
        enter: 'p',
        defaultMode: '1',
        cleanHTML: {
          replaceNewlines: false
        },
        placeholder: 'Enter the answer'
      }) as unknown as Config,
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({ ...formData, textBox: value });
    if (errors.question) {
      setErrors({ ...errors, question: '' });
    }
  };

  const footer = (
    <div className="flex justify-end">
      <button
        type="submit"
        className="  items-center mx-auto w-auto rounded-[7px] py-2 px-[22px] text-xl font-bold shadow-sm focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue-600 bg-blue-600 text-white border-2 border-blue-600 cursor-pointer"
        onClick={isSettings ? settingsFaqAddOrUpdate : handleAddQuestion}
      >
        {editingIndex == 0 || editingIndex ? 'Update' : 'Submit'}{' '}
      </button>
    </div>
  );
  // if (isSettings && isDisable) {
  //   return (
  //     <div className="absolute  top-[50%] left-[65%]">
  //       <ImSpinner9 className="h-10 w-10 text-[#006BEA] animate-spin" />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className={` ml-[4px] md:ml-2 ${isSettings ? 'pe-10 lg:ml-4' : 'pe-5 lg:ml-9'}  `}>
        {(editingIndex === 0 || editingIndex || isAddOrEditPopUpOpen) && (
          <CommonPopup
            onClose={() => (setIsAddOrEditPopUpOpen ? setIsAddOrEditPopUpOpen(false) : undefined)}
            isFaqPopup={true}
          >
            <div className="mt-2">
              <InputBox
                name="question"
                type="text"
                value={formData.textBox}
                placeholder="Enter the question"
                handleChange={(e) => handleChange(e)}
                error={errors.question}
              />
            </div>
            <div>
              <div style={{ marginTop: '10px' }}>
                <JoditEditor
                  value={formData.editors}
                  config={config}
                  onBlur={(newContent) => handleEditorChange(newContent)}
                  onChange={(newContent) => {
                    formData.editors = newContent;
                  }}
                />
                <div className="block h-3">
                  <p className="block h-[16px] my-1 font-normal text-[10px] md:text-[16px] md:leading-[22.8px] error text-[#ff4c4c]">
                    {errors.answer}
                  </p>
                </div>
              </div>
              <div className="flex justify-end">{footer}</div>
            </div>
          </CommonPopup>
        )}

        {isSettings && (
          <div className="flex justify-between items-center  mb-[35px] ">
            <p className="font-medium text-xl md:text-[22px]">{isSettings && "FAQ's"}</p>
            <div
              className="h-12 w-12 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
              onClick={() => setIsAddOrEditPopUpOpen && setIsAddOrEditPopUpOpen(true)}
            >
              <FaPlus className="h-6 w-6" />
            </div>
          </div>
        )}

        <div className="flex flex-col gap-8 my-6 ">
          {(questionsList.length === 0 && !isSettings &&!isLoading) ? (
          (  <div className="absolute  top-[60%] left-[55%]">
              <h2 className="font-bold">No Faqs are available</h2>
            </div>)
          ):( (questionsList.length === 0 && isSettings && !isLoading) &&
           ( <div className="absolute  top-[60%] left-[60%]">
            <h2 className="font-bold">No Faqs are available</h2>
          </div>)
           ) }        
          
          
           {(isLoading && isSettings && questionsList.length !== 0) ?( 
            <div className="absolute  top-[60%] left-[65%]">
              <ImSpinner9 className="h-10 w-10 text-[#006BEA] animate-spin" />
            </div>
          ) : (
            questionsList.map((item, index) => (
              <QuestionItem
                key={index}
                item={item}
                index={index}
                editingIndex={editingIndex}
                handleEditQuestion={handleEditQuestion}
                handleDeleteQuestion={handleDeleteFaq}
                questionsList={questionsList}
              />
            )
          ))}
          
        </div>
      </div>
      {/* {isSettings && <ToastContainer />} */}
      <ToastContainer />
    </>
  );
};

export default RichTextBox;
