import profile1 from '../../../assets/profile1.jpg';
import { MdOutlineStarPurple500 } from 'react-icons/md';

interface Vendor {
  vendorName: string;
  complaints: number;
  solved: number;
  pending: number;
  rating: number;
}

interface EfficiencyTableProps {
  vendors: Vendor[];
}

const EfficiencyTable = ({ vendors }: EfficiencyTableProps) => {
  return (
    <div style={{ height: '347px', overflowY: 'auto' }}>
      <table className="w-full">
        <thead>
          <tr className="md:text-base text-sm  font-bold text-white sticky top-0 z-10 bg-[#343434]">
            <th>Vendors</th>
            <th>Complaints</th>
            <th>Pending</th>
            <th>Solved</th>
            <th>Max time</th>
            <th>Rating</th>
          </tr>
        </thead>
        <tbody>
          {vendors?.map((vendor: Vendor, index: number) => (
            <tr key={index}>
              <td className="pt-5">
                <div className="flex items-center gap-3 ">
                  <div className="justify-start">
                    <div className="w-11 h-11 bg-[#D9D9D9] rounded-full cursor-pointer items-center flex justify-center text-black font-regular text-lg font-bold">
                      {vendor.vendorName[0]?.toUpperCase() || 'A'}
                    </div>
                  </div>
                  <div>
                    <label className="text-base font-normal text-white">{vendor.vendorName}</label>
                  </div>
                </div>
              </td>
              <td className="text-base font-normal text-white text-center pt-5">
                {vendor.complaints}
              </td>
              <td className="text-base font-normal text-white text-center pt-5">
                {vendor.pending}
              </td>
              <td className="text-base font-normal text-white text-center pt-5">{vendor.solved}</td>
              <td className="text-base font-normal text-white text-center pt-5">3min</td>
              <td className="pt-5">
                <ul className="flex md:space-x-4 space-x-0 justify-center">
                  {new Array(5).fill(1).map((_, idx) => (
                    <MdOutlineStarPurple500
                      key={idx}
                      color={idx < vendor.rating ? '#FFF04C' : '#D9D9D9'}
                    />
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EfficiencyTable;
