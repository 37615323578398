import React from 'react';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/style/Calender.css';

interface CalenderProps {
  onDateChange: (date: Date | null) => void;
  selectedDate: Date | null;
  minDate?: Date | null;
  disabled?: boolean;
}

const Calender = ({ onDateChange, selectedDate, minDate, disabled }: CalenderProps) => {
  const handleDateChange = (date: Date | null) => {
    onDateChange(date);
  };
  const dayClassName = (date: Date) => {
    if (minDate && date < minDate) {
      return 'blurred-date';
    }
    return '';
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/mm/yyyy"
      isClearable
      minDate={minDate || undefined}
      dayClassName={dayClassName}
      disabled={disabled}
      customInput={
        <div
          className="relative flex items-center cursor-pointer"
          style={{
            width: '150px',
            textAlign: 'center',
            backgroundColor: '#1E1E1E',
            padding: '8px',
            borderRadius: '8px',
            color: '#FFFFFF',
            border: '1px solid #595959'
          }}
        >
          <FaCalendarAlt
            className="absolute left-2 text-[#B3B3B3]"
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          />
          <input
            type="text"
            className="w-full text-center bg-transparent outline-none"
            style={{ color: 'white', textAlign: 'center' }}
            value={selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''}
            readOnly
            placeholder="dd/mm/yyyy"
          />
        </div>
      }
      calendarClassName="custom-calendar"
      popperClassName="custom-popper"
    />
  );
};

export default Calender;
