import React, { ReactNode, useEffect, useState } from 'react';
import Sidebar from '../../common/layout/Sidebar';
import Stepper from './Stepper';
import Navbar from '../../common/layout/Navbar';
import { getStepperCheck } from '../../api/AccountSetup';
import { useLocation } from 'react-router-dom';
interface AccountSetupWizardProps {
  children: ReactNode;
}
const AccountSetupWizard = (props: AccountSetupWizardProps) => {
  const { children } = props;
  const uuid = sessionStorage.getItem('companyUuid');
  const location = useLocation();
  const [accountDetails, setAccountDetails] = useState<any>(null);
  const steps = [
    {
      id: 1,
      step: 'Create company Profile',
      dbName: 'companyDetail',
      stepUrl: '/account-setup/create-company-profile'
    },
    { id: 2, step: 'FAQ', dbName: 'faq', stepUrl: '/account-setup/faq' },
    { id: 3, step: 'Widgets', dbName: 'widget', stepUrl: '/account-setup/widgets' },
    { id: 4, step: 'Integration', dbName: 'integration', stepUrl: '/account-setup/integration' }
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const fetchData = async () => {
    try {
      const company = await getStepperCheck(uuid ? uuid : '');
      setAccountDetails(company?.payload);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [location.pathname]);

  const handleStepClick = (stepId: number) => {
    setCurrentStep(stepId);
  };
  const header = (
    <h1 className="lg:text-[34px] text-sm font-bold lg:ms-8 ms-1 lg:mt-[40px] lg:mb-[10px] pb-4 lg:ps-4 lg:pb-0">
      Account Setup Wizard
    </h1>
  );

  return (
    <div className="flex  h-screen bg-[1E1E1E] text-white">
      <Sidebar>
        <Stepper
          steps={steps}
          currentStep={currentStep}
          submittedStep={
            accountDetails ?? { companyDetail: '', faq: '', widget: '', integration: '' }
          }
          handleStepClick={handleStepClick}
        />
      </Sidebar>

      <Navbar header={header} isAccountSetup={true}>
        {children}
      </Navbar>
    </div>
  );
};

export default AccountSetupWizard;
