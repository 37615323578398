import React, { Fragment, useEffect, useState } from 'react';
import { IoFilterSharp } from 'react-icons/io5';
import ReactPaginate from 'react-paginate';
import ListOfTickets from './ListOfTickets';
import SpecificTicketDetails from './SpecificTicketDetails';
import AssignUserPopup from './AssignUserPopup';
import ToolTip from '../../common/ToolTip';
import { getComplaint } from '../../api/Complaints';
import VendorAssignedPopup from './VendorAssignedPopup';
import Loader from '../../common/Loader';
import Calender from './Calender';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// import { useSocket } from '../../Contexts/SocketContext';

interface Ticket {
  uuid: string;
  ticketId: string;
  title: string;
  description: string;
  product: string;
  userAssigned: string;
  userUuid: string;
  vendor: string;
  vendorUuid: string;
  status: string;
  raisedOn: string;
}

const Complaints = () => {
  const companyUuid = sessionStorage.getItem('companyUuid');
  const itemsPerPage = 5;
  const statusType = ['Pending', 'Assigned', 'Resolved', 'Reopened'];

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoading, setShowLoading] = useState(true);
  const [showVendorAssignPopup, setShowVendorAssignPopup] = useState(false);
  const [selectedComplaintsInfo, setSelectedComplaintsInfo] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [clearButtonShow, setClearButtonShow] = useState(false);
  // const { socketInstance } = useSocket();

  const handleFetchTickets = async (
    type: string,
    page: number,
    from: string,
    to: string,
    defaultSelectionIndex: number
  ) => {
    try {
      setShowLoading(true);
      const data = await getComplaint(
        companyUuid ? companyUuid : '',
        type,
        page,
        itemsPerPage,
        from,
        to
      );
      setTickets(data?.payload?.complaintList || []);
      setSelectedComplaintsInfo(data?.payload?.complaintList[defaultSelectionIndex] || '');
      setTotalItems(data?.payload?.count || 0);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      console.error('Error fetching complaints data:', error);
    }
  };

  const normalizeToUTC = (date: Date): string => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return utcDate.toISOString();
  };

  const handleFilterClick = (type: string) => {
    setStartDate(null);
    setEndDate(null);
    setCurrentPage(1);
    handleFetchTickets(type, currentPage, '', '', 0);
    setClearButtonShow(true);

  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };
  const handleStartDateChange = (selectedDate: Date | null) => {
    setStartDate(selectedDate);
    if (selectedDate || !selectedDate) {
      setEndDate(null);
    }
    if (!selectedDate) {
      handleFetchTickets('All', currentPage, '', '', 0);
setClearButtonShow(false);

    }
  };

  const handleEndDateChange = (selectedDate: Date | null) => {
    setEndDate(selectedDate);
    if (startDate && selectedDate) {
      handleFetchTickets(
        '',
        currentPage,
        normalizeToUTC(startDate),
        normalizeToUTC(selectedDate),
        0
      );
    }
setClearButtonShow(true);

  };
  const handelClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    handleFetchTickets('All', currentPage, '', '', 0);
setClearButtonShow(false);

  };

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    handleFetchTickets('All', currentPage, '', '', 0);
  }, [currentPage]);
  return (
    <>
      <div className="bg-[#1E1E1E] h-screen py-5 w-full lg:px-5 px-2 overflow-y-auto xl:overflow-hidden relative">
        <div className="flex justify-between items-center mb-6">
          <label className="text-xl text-white font-bold">Tickets</label>
          <div className="flex justify-between items-center gap-4">
          {clearButtonShow && (
              <button
                className="bg-[#006BEA] text-[#FFFFFF] rounded-[6px] p-[10px] font-semibold"
                onClick={handelClearFilter}
              >
                Clear Filter
              </button>
            )}
            <div className="gap-3 flex items-center">
              <div className="w-auto">
                <Calender selectedDate={startDate} onDateChange={handleStartDateChange} />
              </div>
              <label className="font-medium">To</label>
              <div className="w-auto">
                <Calender
                  selectedDate={endDate}
                  onDateChange={handleEndDateChange}
                  minDate={startDate}
                  disabled={!startDate}
                />
              </div>
            </div>
            <div className="relative">
              <IoFilterSharp
                className="cursor-pointer"
                color={'#006BEA'}
                size={25}
                onClick={() => setShowTooltip(true)}
              />
              <ToolTip
                width={'w-[200px]'}
                height={'h-[150px]'}
                isVisible={showTooltip}
                onClose={() => setShowTooltip(false)}
              >
                <ul className="flex flex-col text-[#006BEA] font-bold text-base justify-center items-center gap-3 mt-1 cursor-pointer">
                  {statusType.map((item) => (
                    <li
                      key={item}
                      onClick={() => {
                        handleFilterClick(item);
                        setShowTooltip(false);
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </ToolTip>
            </div>
          </div>
        </div>
        {showLoading ? (
          <div className="flex  justify-center items-center absolute inset-0">
            <Loader />
          </div>
        ) : (
          <Fragment>
            {tickets.length > 0 ? (
              <div className="bg-[#1E1E1E] h-screen  w-full lg:px-5 px-2 overflow-y-auto xl:overflow-hidden">
                <div className="flex flex-col xl:flex-row gap-2.5 pb-24 h-auto xl:pb-0">
                  <div className="w-full xl:w-[40%]">
                    <ListOfTickets
                      tickets={tickets || []}
                      selectedComplaintsInfo={selectedComplaintsInfo}
                      setSelectedComplaintsInfo={setSelectedComplaintsInfo}
                    />
                  </div>

                  <div className="w-full xl:w-[60%] bg-[#343434] rounded-lg p-2 mt-2">
                    <SpecificTicketDetails
                      tickets={tickets}
                      setShowAssignPopup={setShowAssignPopup}
                      setShowVendorAssignPopup={setShowVendorAssignPopup}
                      selectedComplaintsInfo={selectedComplaintsInfo}
                      handleFetchTickets={handleFetchTickets}
                      currentPage={currentPage}
                    />
                  </div>
                </div>

                {pageCount > 1 && (
                  <div className="flex justify-center w-full mt-1 xl:w-[40%]">
                    <ReactPaginate
                      previousLabel={
                        <div className="flex items-center justify-center w-8 h-7 border rounded border-white text-white">
                          <FaChevronLeft />
                        </div>
                      }
                      nextLabel={
                        <div className="flex items-center justify-center w-8 h-7 border rounded border-white text-white">
                          <FaChevronRight />
                        </div>
                      }
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      forcePage={currentPage - 1}
                      containerClassName="pagination flex space-x-6"
                      pageLinkClassName="cursor-pointer"
                      activeLinkClassName="text-blue-500 font-bold"
                      disabledClassName="opacity-50 cursor-not-allowed"
                      previousClassName={currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}
                      nextClassName={
                        currentPage === pageCount ? 'opacity-50 cursor-not-allowed' : ''
                      }
                    />
                  </div>
                )}

                {showAssignPopup && (
                  <AssignUserPopup
                    tickets={tickets}
                    onClose={() => setShowAssignPopup(false)}
                    selectedComplaintsInfo={selectedComplaintsInfo}
                    handleFetchTickets={handleFetchTickets}
                    currentPage={currentPage}
                  />
                )}
                {showVendorAssignPopup && (
                  <VendorAssignedPopup
                    tickets={tickets}
                    onClose={() => setShowVendorAssignPopup(false)}
                    selectedComplaintsInfo={selectedComplaintsInfo}
                    handleFetchTickets={handleFetchTickets}
                    currentPage={currentPage}
                  />
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center h-3/6 w-full text-white text-lg font-bold">
                No record found
              </div>
            )}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default Complaints;
